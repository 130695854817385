import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import steeringWheel from 'app/images/tesla-steering-wheel-source.jpeg';
import { Button } from 'components/atoms/Button';
import { Modal } from 'components/modals/Modal';
import { Site } from 'models/site';

type ChargingAndReservationInfoModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  site: Site;
  paymentReceiver: string;
  bookedAmount: number;
};

export const ChargingAndReservationInfoModal = ({
  isOpen,
  closeModal,
  site,
  paymentReceiver,
  bookedAmount,
}: ChargingAndReservationInfoModalProps) => {
  const { t } = useTranslation();
  const freeReservationMinutes = site?.settings?.chargerReservation?.freeMinutes ?? 0;
  const isReservationEnabled = site?.settings?.chargerReservation?.isEnabled;
  const isParkingEnabled = site?.settings?.parking?.isEnabled;
  const freeParkingMinutes = site?.settings?.parking?.freeMinutes ?? 0;
  // TODO: VAT might be undefined
  const getPriceEurosPerMinute = (cents?: number) =>
    ((cents ?? 0) / 100) * (site?.vatMultiplier ?? 1);
  const reservationPriceEurosPerMinutes = getPriceEurosPerMinute(
    site?.settings?.chargerReservation?.priceCentsPerMinute,
  ).toFixed(2);
  const parkingPriceEurosPerMinutes = getPriceEurosPerMinute(
    site?.settings?.parking?.priceCentsPerMinute,
  ).toFixed(2);

  return (
    <Modal open={isOpen} onClose={closeModal} coverImage={steeringWheel}>
      <Box className="flex flex-col" gap={3}>
        <div className="text-3xl sm:whitespace-nowrap ">
          {t('ChargingAndPricingInformation', 'Charging & pricing information').toString()}
        </div>
        <ul>
          <li>
            {`• ${t(
              'chargingWillStartAfterPayment',
              'Charging will automatically start after payment.',
            )}`}
          </li>
          <li>
            {`• ${t(
              'paymentMessage',
              '{{bookedAmount}} will be booked on your account by {{paymentReceiver}}',
              { paymentReceiver, bookedAmount },
            )}`}
          </li>
          {isReservationEnabled &&
            (freeReservationMinutes > 0 ? (
              <li>
                {`• ${t(
                  'reservationInfoWithFreeMinutes',
                  'To reserve: {{freeReservationMinutes}} min free, then {{reservationPriceEurosPerMinutes}} €/min',
                  { freeReservationMinutes, reservationPriceEurosPerMinutes },
                )}`}
              </li>
            ) : (
              <li>
                {`• ${t(
                  'reservationInfo',
                  'To reserve: {{reservationPriceEurosPerMinutes}} €/min',
                  { reservationPriceEurosPerMinutes },
                )}`}
              </li>
            ))}
          {isParkingEnabled &&
            (freeParkingMinutes > 0 ? (
              <li>
                {`• ${t(
                  'parkingFeeWithFreeMinutes',
                  'Parking fee: {{freeParkingMinutes}} min free, then {{parkingPriceEurosPerMinutes}} €/min will be applied if your car stays here after the battery is full',
                  { parkingPriceEurosPerMinutes, freeParkingMinutes },
                )}`}
              </li>
            ) : (
              <li>
                {`• ${t(
                  'parkingFee',
                  'Parking fee: {{parkingPriceEurosPerMinutes}} €/min will be applied if your car stays here after the battery is full',
                  { parkingPriceEurosPerMinutes },
                )}`}
              </li>
            ))}
          <li>{`• ${t('minimumFee', 'Minimum fee 0.50€')}`}</li>
        </ul>
        <div className="flex w-full self-center pb-4 max-w-[343px]">
          <Button
            radiusClass="rounded-full"
            className="w-full py-4 bg-vool-gray-200"
            textColor="black"
            fontSize="text-md"
            onClick={closeModal}
          >
            {t('back', 'Back').toString()}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
