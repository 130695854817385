import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { buildAuthEndpoints } from 'services/auth/endpoints';
import { buildBillingEndpoints } from 'services/billing/endpoints';
import { buildDevicesEndpoints } from 'services/devices/endpoints';
import { buildParkingAndReservationEndpoints } from 'services/parkingAndReservations/endpoints';
import { buildPortalEndpoints } from 'services/portal/endpoints';
import { buildSitesEndpoints } from 'services/sites/endpoints';
import { buildTransactionsEndpoints } from 'services/transactions/endpoints';
import { buildVehiclesEndpoints } from 'services/vehicles/endpoints';

export type ApiEndpointBuilder = EndpointBuilder<
  (
    args: any,
    api: any,
    extraOptions: any,
  ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
  never,
  'api'
>;

export const buildEndpoints = (builder: ApiEndpointBuilder) => ({
  ...buildAuthEndpoints(builder),
  ...buildTransactionsEndpoints(builder),
  ...buildSitesEndpoints(builder),
  ...buildBillingEndpoints(builder),
  ...buildPortalEndpoints(builder),
  ...buildDevicesEndpoints(builder),
  ...buildParkingAndReservationEndpoints(builder),
  ...buildVehiclesEndpoints(builder),
});
