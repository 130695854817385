import { Fragment, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dialog, Transition } from '@headlessui/react';
import { mdiExclamationThick } from '@mdi/js';
import Icon from '@mdi/react';

import exitIcon from 'app/images/icons/exitWithBackground.svg';
import { Button } from 'components/atoms/Button';
import moment from 'moment';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectSiteVatMultiplier } from 'services/sites/selectors';

type CancelReservationModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  cancelReservation: () => Promise<any>;
  onCancel: (message: string) => void;
};

export const CancelReservationModal = ({
  isOpen,
  closeModal,
  cancelReservation,
  onCancel,
}: CancelReservationModalProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const activeReservation = useSelector(selectActiveReservation);
  const startReservationTimestamp = activeReservation?.startTime;
  // TODO: Logic needs fixing, shouldn't use so many ?? operators
  const vatMultiplier =
    useSelector(selectSiteVatMultiplier(activeReservation?.siteUuid ?? '')) ?? 1;
  const reservationEurosPerMin =
    ((activeReservation?.priceCentsPerMinute ?? 0) / 100) * vatMultiplier;
  const freeReservationSeconds = (activeReservation?.freeMinutes ?? 0) * 60;
  const paidReservationStart = moment(startReservationTimestamp).add(
    freeReservationSeconds,
    'seconds',
  );
  const isAfterPaidTime = moment().isAfter(paidReservationStart);

  const getPaidReservationCost = () => {
    const durationInMinutes = moment().diff(moment(paidReservationStart), 'seconds') / 60;
    const cost = durationInMinutes * reservationEurosPerMin;
    return Math.max(cost, 0.5);
  };

  const handleCancelReservation = async () => {
    const response = await cancelReservation();

    const toastMessage = response?.error
      ? t('CancelReservationFailed', 'Failed to cancel reservation')
      : t('CancelReservationSuccess', 'Reservation was cancelled successfully');

    onCancel(toastMessage);
  };

  const cost = getPaidReservationCost().toFixed(2);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" onClose={closeModal} className="fixed z-30 inset-0 overflow-y-auto">
        <div ref={containerRef} className="w-full min-h-full flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-raisingBlack bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 sm:scale-95"
            enterTo="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 sm:scale-100"
            leaveTo="opacity-0 sm:scale-95"
          >
            <div className="bg-white px-4 sm:rounded-2xl overflow-hidden transform transition-all w-full flex flex-col items-center sm:max-w-login min-h-screen sm:min-h-0 sm:p-8">
              <button
                className="absolute p-0 top-8 left-auto right-4 sm:right-8"
                type="button"
                onClick={closeModal}
              >
                <img src={exitIcon} alt="exit" className="h-12 w-12 flex" />
              </button>
              <div className="text-left pb-14 flex flex-col mt-20 w-full max-w-[344px]">
                <div className="border-4 border-primary rounded-full w-20 h-20 flex items-center justify-center">
                  <div className="rounded-full border-2 w-[26px] h-[26px] border-primary flex items-center justify-center">
                    <Icon path={mdiExclamationThick} className="text-primary" size="18px" />
                  </div>
                </div>
                <span className="text-[32px] mt-4">
                  {t('cancelReservation', 'Cancel reservation').toString()}?
                </span>
                <span className="pb-5 text-sm">
                  {!isAfterPaidTime ? (
                    <Trans i18nKey="youAreOnFreeReservation">
                      You are on <strong>free reservation</strong>
                    </Trans>
                  ) : (
                    <Trans i18nKey="youAreOnPaidReservationAndCost" values={{ cost }}>
                      You are on <strong>paid reservation</strong> and <strong>{cost}€</strong> will
                      be taken from your account after cancellation.
                    </Trans>
                  )}
                </span>
                <div className="flex space-x-2">
                  <Button
                    radiusClass="rounded-full"
                    className="w-full py-4 bg-vool-gray-200"
                    textColor="black"
                    fontSize="text-md"
                    onClick={closeModal}
                  >
                    {t('no', 'No').toString()}
                  </Button>
                  <Button
                    radiusClass="rounded-full"
                    className="w-full py-4"
                    fontSize="text-md"
                    onClick={() => {
                      closeModal();
                      handleCancelReservation();
                    }}
                  >
                    {t('yes', 'Yes').toString()}
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
