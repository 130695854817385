import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Spinner } from 'components/atoms/Spinner';
import { capitalize, findIndex } from 'lodash';
import apiSlice from 'services/api';
import { selectPaymentMethods } from 'services/billing/selectors';

import { PaymentItem } from './PaymentItem';

export const PaymentMethods = () => {
  const { t } = useTranslation();
  const paymentMethods = useSelector(selectPaymentMethods);
  const orderedPaymentMethods = [...paymentMethods].sort((a, b) => b.defaultFlag - a.defaultFlag);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
  const { isSuccess, isError, isFetching } = apiSlice.useGetPaymentMethodsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [setDefault] = apiSlice.useSetPaymentMethodAsDefaultMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setPaymentMethodsLoading(false);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    const setDefaultMethod = async () => {
      setPaymentMethodsLoading(true);
      await setDefault(paymentMethods[0].id);
      setPaymentMethodsLoading(false);
    };
    if (paymentMethods.length > 0 && findIndex(paymentMethods, ['defaultFlag', 1]) === -1) {
      setDefaultMethod();
    }
  }, [paymentMethods]);

  if (paymentMethodsLoading || isFetching) {
    return (
      <div className="w-full flex items-center justify-center">
        <Spinner size={14} />
      </div>
    );
  }

  if (!paymentMethods.length) {
    return (
      <div className="py-1 pb-4 w-full">
        <div className="py-5 border bg-white shadow-sm flex items-center justify-center font-normal text-base rounded-2xl">
          {t('noPaymentMethods', 'No payment methods added')} 😔
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start justify-start py-1 pb-2 w-full sm:flex-row sm:flex-wrap">
      {orderedPaymentMethods.map(({ id, defaultFlag, card }) => (
        <div className="w-full sm:w-96 pb-2 sm:pr-2" key={id}>
          <PaymentItem
            id={id}
            // cardType={card.brand} TODO: Seems to be not used
            brand={capitalize(card.brand)}
            number={`**** ${card.last4}`}
            expiryDate={`${card.exp_month}.${card.exp_year}`}
            defaultFlag={defaultFlag}
          />
        </div>
      ))}
    </div>
  );
};
