import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useMediaQuery } from '@mui/material';

import vehicleIcon from 'app/images/icons/vehicleIcon.svg';
import { Card } from 'components/atoms/Card';
import { Car } from 'models/car';
import apiSlice from 'services/api';
import { setDefaultVehicle } from 'services/vehicles';
import { selectDefaultVehicle, selectVehicles } from 'services/vehicles/selectors';

import { AddVehicleModal } from './AddVehicleModal';
import { VehicleDrawer } from './VehicleDrawer';
import { VehicleModal } from './VehicleModal';

export const VehicleCard = () => {
  const { t } = useTranslation();
  const vehicles = useSelector(selectVehicles);
  const defaultVehicle = useSelector(selectDefaultVehicle);
  const dispatch = useDispatch();
  apiSlice.useGetCarsQuery({});

  const [vehicleDrawerOpen, setVehicleDrawerOpen] = useState(false);
  const [vehicleModalOpen, setVehicleModalOpen] = useState(false);
  const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
  const isMobileView = useMediaQuery('(max-width: 600px)');

  const handleVehicleCardClick = (): void => {
    if (vehicles.length === 0) {
      setAddVehicleModalOpen(true);
    } else {
      if (!isMobileView) {
        setVehicleDrawerOpen(false);
        setVehicleModalOpen(true);
      } else {
        setVehicleModalOpen(false);
        setVehicleDrawerOpen(true);
      }
    }
  };

  const handleVehicleItemClick = (vehicleId?: string) => {
    if (vehicleId) {
      const clickedVehicle = vehicles.find((vehicle: Car) => vehicle.carId === vehicleId);
      dispatch(setDefaultVehicle(clickedVehicle));
    } else {
      dispatch(setDefaultVehicle(undefined));
    }

    if (vehicleModalOpen) {
      setVehicleModalOpen(false);
    }

    if (vehicleDrawerOpen) {
      setVehicleDrawerOpen(false);
    }
  };

  return (
    <>
      <button type="button" onClick={handleVehicleCardClick}>
        <Card
          wrapperClass="px-4 sm:px-6 text-center pb-4"
          className="p-2 bg-vool-gray-100"
          full
          radiusClass="rounded-2xl"
          borderless
        >
          <div className="flex w-full text-left items-center">
            <div className="p-2">
              <img src={vehicleIcon} alt="card" sizes="24px" className="text-primary" />
            </div>
            <div className="flex flex-grow p-2 flex-col">
              <span className="font-semibold">
                {defaultVehicle
                  ? `${defaultVehicle.brand} ${defaultVehicle.model}`
                  : t('unknownVehicle', 'Unknown vehicle')}
              </span>
              {defaultVehicle ? (
                <span className="text-sm text-raisingBlack">
                  {t('battery', 'Battery')}: {defaultVehicle.status?.batteryLevel}%
                </span>
              ) : (
                <span className="text-sm text-raisingBlack">
                  {t('tapToLearnMore', 'Tap to learn more')}
                </span>
              )}
            </div>
            <div className="p-2">
              <Icon path={mdiArrowRight} size="24px" />
            </div>
          </div>
        </Card>
      </button>
      <AddVehicleModal
        isOpen={addVehicleModalOpen}
        closeModal={() => setAddVehicleModalOpen(false)}
      />
      <VehicleDrawer
        isOpen={vehicleDrawerOpen}
        close={() => setVehicleDrawerOpen(false)}
        onVehicleItemClick={handleVehicleItemClick}
        vehicles={vehicles}
      />
      <VehicleModal
        isOpen={vehicleModalOpen}
        close={() => setVehicleModalOpen(false)}
        onVehicleItemClick={handleVehicleItemClick}
        vehicles={vehicles}
      />
    </>
  );
};
