import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import { Notification } from 'app/views/Notifications/Notification';
import { LiquidBattery } from 'components/animations/LiquidBattery';
import { DurationCounter } from 'components/atoms/DurationCounter';
import { TransactionHandler } from 'handlers/transaction.handler';
import { Transaction } from 'models/transaction';
import { trackAmplitudeEvent } from 'services/amplitude';
import {
  AMPLITUDE_EVENTS,
  getAmplitudeEventPropertiesForTransaction,
} from 'services/amplitude/data';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';
import { selectIsChargerOpen } from 'services/portal/selectors';
import { selectDevice } from 'services/sites/selectors';

const useActivePower = ({ deviceUuid, connectorId }: Transaction) => {
  const device = useSelector(selectDevice(deviceUuid));
  const { data } = apiSlice.useGetDeviceStatusQuery(deviceUuid);
  return (
    device?.activePower ||
    data?.deviceStatus?.connectors?.find(({ connector_id }) => connector_id === connectorId)
      ?.active_power
  );
};

type ActiveTransactionSnackbarProps = {
  activeTransaction: Transaction;
};

export const ActiveTransactionSnackbar = ({
  activeTransaction,
}: ActiveTransactionSnackbarProps) => {
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const activeTransactionHandler = new TransactionHandler(activeTransaction);

  const activeTransactionDeviceUuid = activeTransaction?.deviceUuid;
  const activePower = useActivePower(activeTransaction);

  const isChargerOpen = useSelector(selectIsChargerOpen);

  const handleNavigateToChargingDevice = () => {
    trackAmplitudeEvent(
      AMPLITUDE_EVENTS.CHARGING_SESSION_SNACKBAR,
      getAmplitudeEventPropertiesForTransaction({
        transaction: activeTransaction,
        user,
      }),
    );
    navigate(`/sites?site=${activeTransaction?.siteUuid}&device=${activeTransactionDeviceUuid}`);
  };

  return (
    <Notification
      onClick={handleNavigateToChargingDevice}
      backgroundColor="bg-raisingBlack"
      className="h-[72px]"
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex justify-start items-center">
          <LiquidBattery
            title={activeTransactionHandler?.getAmount(2).split(' ')[0] ?? ''}
            description="kWh"
          />
          <div className="flex flex-col justify-center items-start ml-2">
            <span className="text-sm font-semibold text-white">{activeTransaction?.siteName}</span>
            <p className="text-xs font-normal opacity-75 text-white">
              <DurationCounter datetime={activeTransaction?.startTime} />
              <span className="p-2">•</span>
              {activeTransactionHandler?.getMonetaryRevenueWithVAT(2)}
              <span className="p-2">•</span>
              {activePower?.toFixed(2) ?? '--'} kW
            </p>
          </div>
        </div>
        {!isChargerOpen && (
          <img src={arrowRightIcon} alt="arrow" className="filter invert w-6 h-6 mr-3" />
        )}
      </div>
    </Notification>
  );
};
