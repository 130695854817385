import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Global } from '@emotion/react';

import { SwipeButton } from 'components/atoms/SwipeButton';
import { ReserveChargerButton } from 'components/reserveCharger/ReserveChargerButton';
import { format } from 'date-fns';
import { getEvseActiveCharge, getEvseStatus } from 'handlers/device/device.handler';
import { OcppStatus } from 'handlers/device/ocpp-status.enum';
import { Evse } from 'models/device';
import { Site } from 'models/site';
import { TransactionStatus } from 'models/transaction';
import { selectUser } from 'services/auth/selectors';
import { selectPaymentMethods } from 'services/billing/selectors';
import { selectFooterNotification } from 'services/notifications/selectors';
import {
  selectActiveParking,
  selectActiveReservation,
} from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { ChargeIntroMessage } from './ChargeIntroMessage';
import { ChargerInfoMessage } from './ChargerInfoMessage';

type ChargerActionContainerProps = {
  site: Site;
  device: Evse;
  startChargeHandler: () => void;
  stopChargeHandler: () => void;
  buttonLoading: boolean;
  startPayment: () => void;
  onMessage: (message: string) => void;
};

export const ChargerActionContainer = ({
  site,
  device,
  startChargeHandler,
  stopChargeHandler,
  buttonLoading,
  startPayment,
  onMessage,
}: ChargerActionContainerProps) => {
  const { t } = useTranslation();
  const activeTransaction = useSelector(selectActiveTransaction);
  const activeCharge = getEvseActiveCharge(device, activeTransaction);
  const activeReservation = useSelector(selectActiveReservation);
  const status = getEvseStatus(device, activeTransaction, activeReservation);
  const hasAnotherChargeInProgress = activeTransaction !== undefined && !activeCharge;
  const paymentMethods = useSelector(selectPaymentMethods);
  const user = useSelector(selectUser);
  const footerNotification = useSelector(selectFooterNotification);
  const activeParking = useSelector(selectActiveParking);

  return (
    <div>
      <Global
        styles={{
          '.vool-charger-footer': {
            paddingBottom: '24px',
            '@media (max-width: 568px)': {
              paddingBottom: `${16 + footerNotification.height}px`,
            },
          },
        }}
      />
      <div className="vool-charger-footer px-4 sm:px-6 w-full flex flex-col rounded-t-3xl shadow pt-6">
        {hasAnotherChargeInProgress && (
          <ChargerInfoMessage
            message={t(
              'chargeInProgressDescription',
              'Charging session already running at another charger',
            )}
          />
        )}

        {status === OcppStatus.AVAILABLE &&
          !hasAnotherChargeInProgress &&
          activeTransaction?.status !== TransactionStatus.Pending && (
            <ChargerInfoMessage
              message={`${t(
                'statusMessage',
                'You need to plug-in the charger, to start charging',
              )} 🔌`}
            />
          )}

        {activeParking?.deviceUuid && (
          <ChargerInfoMessage
            message={`${t(
              'parkingInfoMessage',
              'Please disconnect cable to finish session. Parking started at',
            )}`}
          >{` ${format(new Date(activeParking.startTime), 'HH:mm')}.`}</ChargerInfoMessage>
        )}

        <div className="flex flex-col space-y-2 w-full">
          {!activeCharge ? (
            <SwipeButton
              className="bg-vool-green"
              text={t('startCharging', 'Start charging')}
              disabled={status !== OcppStatus.PLUGGED_IN || hasAnotherChargeInProgress}
              loading={buttonLoading}
              onSuccess={paymentMethods?.length ? startChargeHandler : startPayment}
            />
          ) : (
            <SwipeButton
              className="bg-primary"
              text={
                activeTransaction?.status === TransactionStatus.Pending
                  ? t('cancel', 'Cancel')
                  : t('stopCharging', 'Stop charging')
              }
              disabled={status === OcppStatus.OFFLINE}
              loading={buttonLoading}
              onSuccess={stopChargeHandler}
            />
          )}
          {!activeCharge && device && (
            <ReserveChargerButton
              user={user}
              status={status}
              device={device}
              site={site}
              onMessage={(message) => onMessage(message)}
              hasPaymentMethods={!!paymentMethods.length}
              startPayment={startPayment}
            />
          )}
          <ChargeIntroMessage hasPaymentMethods={!!paymentMethods.length} site={site} />
        </div>
      </div>
    </div>
  );
};
