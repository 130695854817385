import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { CardItem } from 'components/basicWrappers/CardItem';
import moment from 'moment';
import apiSlice from 'services/api';
import { selectSiteHourlyPrices, selectSiteVatMultiplier } from 'services/sites/selectors';

type EnergyPriceProps = {
  title: string;
  borderless?: boolean;
  full?: boolean;
  siteUuid: string;
};

export const EnergyPrice = ({
  title = 'Energy price 24h',
  borderless,
  full,
  siteUuid,
}: EnergyPriceProps) => {
  // selectors
  const hourlyPrices = useSelector(selectSiteHourlyPrices(siteUuid));
  // states
  const [currentTime, setCurrentTime] = useState(moment().local().format('HH:mm'));
  // consts
  const vatMultiplier = useSelector(selectSiteVatMultiplier(siteUuid)) ?? 1; // TODO: VAT multiplier might be missing
  const seriesData = useMemo(
    () =>
      Object.entries(hourlyPrices || {}).map(([timestamp, price]) => ({
        hour: moment(Number(timestamp) * 1000).format('HH'),
        formattedValue: price ? (price * vatMultiplier).toFixed(2) : '',
      })),
    [hourlyPrices],
  );
  const getTimeAround = (hoursToAdd = 0) => moment().add(hoursToAdd, 'hours');
  const formattedCurrentHour = moment().local().format('HH');
  const footerData = useMemo(
    () =>
      [0, 1, 2].map((startHourDelta) => ({
        period: `${getTimeAround(startHourDelta).format('HH:00')} - ${getTimeAround(
          startHourDelta + 1,
        ).format('HH:00')}`,
        formattedValue: seriesData?.find(
          ({ hour }) => hour === getTimeAround(startHourDelta).format('HH'),
        )?.formattedValue,
      })),
    [seriesData],
  );

  const { isFetching } = apiSlice.useGetSiteHourlyPricesQuery(siteUuid, {
    skip: !!hourlyPrices,
  });

  // effects
  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(moment().local().format('HH:mm'));
    }, 1000 * 60);

    return () => clearInterval(currentTimeInterval);
  }, []);

  return (
    <CardItem
      title={title}
      wrapperClass="h-full"
      className="h-full dark:text-white"
      borderless={borderless}
      full={full}
      loading={isFetching}
    >
      <div className="relative w-full h-full min-h-energyPrice text-vool-gray-400">
        <span className="absolute top-0 left-1 font-normal text-xxs text-vool-gray-400">
          cents/kWh
        </span>
        <Chart
          type="line"
          width="100%"
          height="100%"
          options={{
            responsive: [{ breakpoint: undefined, options: {} }],
            tooltip: {
              enabled: true,
              x: {
                show: false,
              },
              y: {
                formatter: (value) => value.toString(),
              },
            },
            stroke: {
              show: true,
              curve: 'stepline',
              lineCap: 'butt',
              width: 1,
              dashArray: 0,
            },
            markers: { size: 0 },
            dataLabels: {
              enabled: false,
            },
            chart: {
              id: 'basic-bar',
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              categories: seriesData?.map((item) => item.hour),
              labels: {
                showDuplicates: false,
                rotate: 0,
                style: {
                  fontSize: '10px',
                  fontFamily: 'Poppins, sans-serif',
                  colors: '#999898',
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickAmount: 12,
            },
            yaxis: {
              decimalsInFloat: 0,
              labels: {
                style: {
                  fontSize: '9px',
                  fontFamily: 'Poppins, sans-serif',
                  colors: '#282828',
                },
                offsetX: -10,
              },
              axisBorder: {
                show: false,
              },
            },
            grid: {
              show: true,
              borderColor: '#f1f1f1',
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            },
            colors: ['gray'],
            annotations: {
              xaxis: [
                {
                  x: moment().local().format('HH'),
                  borderColor: '#282828',
                  // borderStyle: 'solid',
                },
              ],
              points: [
                {
                  x: formattedCurrentHour,
                  y: Number(
                    seriesData?.find(({ hour }) => hour === formattedCurrentHour)?.formattedValue,
                  ),
                  marker: {
                    size: 4,
                    fillColor: '#999898',
                    strokeColor: '#999898',
                  },
                  label: {
                    borderColor: 'white',
                    orientation: 'horizontal',
                    borderRadius: 4,
                    offsetY: -8,
                    style: {
                      color: '#282828',
                      background: 'white',
                      // borderWidth: 1,
                    },
                    text: currentTime,
                  },
                },
              ],
            },
          }}
          series={[
            {
              name: 'Price (cents/kWh)',
              data: seriesData?.map((item) => Number(item.formattedValue)),
            },
          ]}
        />
      </div>
      <div className="flex justify-between w-full py-4 px-4">
        {footerData.map(({ period, formattedValue }) => (
          <div key={period} className="flex flex-col">
            <span className="font-normal text-xs text-vool-gray-400">{period}</span>
            <span className="font-semibold text-base text-app-header-link">{formattedValue}</span>
            <span className="font-normal text-xs text-vool-gray-400">cents/kWh</span>
          </div>
        ))}
      </div>
    </CardItem>
  );
};
