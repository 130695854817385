import { Navigate, RouteProps } from 'react-router-dom';

import { AccountView } from 'app/views/Account';
import { Billing } from 'app/views/Billing/Billing';
import { ChargeHistory } from 'app/views/ChargingHistory';
import { Logout } from 'app/views/Logout';
import { PublicSites } from 'app/views/PublicSites/PublicSites';
import { ResetPassword } from 'app/views/ResetPassword';

const DEFAULT_PATH = '/sites';

export const customerPortalPublicRoutes: RouteProps[] = [
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/',
    element: <Navigate replace to={DEFAULT_PATH} />,
  },
  {
    path: '/sites',
    element: <PublicSites />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
];

export const customerPortalPrivateRoutes: RouteProps[] = [
  {
    path: '/billing',
    element: <Billing />,
  },
  {
    path: '/charge-history',
    element: <ChargeHistory />,
  },
  {
    path: '/account',
    element: <AccountView />,
  },
];
