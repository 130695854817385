import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { toggleFiltersOpen } from 'services/filters';

type SiteListHeaderProps = {
  isMobile: boolean;
  numberOfSites: number;
};

export const SiteListHeader = ({ isMobile, numberOfSites }: SiteListHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Box display="flex" flexDirection="column" textAlign={isMobile ? 'center' : 'left'} px={3}>
      <Typography variant="h3" marginTop={1}>
        {t('publicSiteListTitle', 'Choose nearby chargers')}
      </Typography>
      <Typography variant="p14" color="#999898">
        {numberOfSites} {t('publicSiteListSubtitle', 'chargers available')}
      </Typography>
      <Box>
        <Typography
          variant="p16"
          onClick={() => dispatch(toggleFiltersOpen())}
          sx={{
            color: '#EB4E20',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {t('filters', 'Filters')}
        </Typography>
      </Box>
    </Box>
  );
};
