import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { OutletContext } from 'app/layouts/LoggedinBasicLayout/Layout';
import { Button } from 'components/atoms/Button';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';

import { AuthModal } from './AuthModal';

export const TryNowBox = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { initAuth, setInitAuth } = useOutletContext<OutletContext>();

  const [authOpen, setAuthOpen] = useState(false);

  const initiateLogin = () => {
    setAuthOpen(true);
    trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_LOGIN, {
      ...getAmplitudeEventPropertiesForUser(user),
      startingPoint: 'siteDetails',
    });
  };

  useEffect(() => {
    if (initAuth) {
      setAuthOpen(initAuth);
    }
  }, [initAuth]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex mb-4 bg-vool-black text-white text-sm font-normal px-4 py-4 rounded-2xl">
          <span className="pr-2">
            {t('tryNowIntro', 'Create an account to get additional functionality')}
          </span>
          <Button
            className="whitespace-nowrap"
            radiusClass="rounded-full"
            color="bg-white"
            textColor="text-black"
            onClick={initiateLogin}
          >
            <span className="font-semibold text-base">{t('tryNow', 'Try now')}</span>
          </Button>
        </div>
      </div>
      <AuthModal
        open={authOpen}
        closeModal={() => {
          setAuthOpen(false);
          setInitAuth(false);
        }}
      />
    </>
  );
};
