import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-spring-bottom-sheet/dist/style.css';

import { CardItem } from 'components/basicWrappers/CardItem';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import apiSlice from 'services/api';
import { updateActiveSite, updateActiveSiteByUuid } from 'services/sites/actions';
import { selectActiveSite } from 'services/sites/selectors';

import { EmptyView } from './EmptyView';
import { GoogleMap } from './GoogleMap/GoogleMap';
import { PublicSiteList } from './PublicSiteList';
import { SlideBar } from './SlideBar';

export const PublicSites = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const urlSiteUuid = searchParams.get('site');
  const [destination, setDestination] = useState<google.maps.Place>();
  const { isSuccess, isLoading } = apiSlice.usePublicSitesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const activeSite = useSelector(selectActiveSite);

  useEffect(() => {
    if (urlSiteUuid) {
      dispatch(updateActiveSiteByUuid(urlSiteUuid));
    }
  }, [urlSiteUuid, isSuccess]);

  if (isLoading) {
    return (
      <CardItem>
        <div className="p-6">
          <OverflowSpinner size={16} />
        </div>
      </CardItem>
    );
  }

  if (!isSuccess) {
    return <EmptyView />;
  }

  return (
    <div className="flex h-full w-full">
      <PublicSiteList />
      <div className={`w-full h-full ${activeSite ? 'sm:max-w-map-with-slidebar' : ''}`}>
        <GoogleMap destination={destination} />
      </div>
      {activeSite && (
        <SlideBar
          site={activeSite}
          isOpen={activeSite !== undefined}
          closeSlidebar={() => {
            dispatch(updateActiveSite(undefined));
            navigate({
              search: '',
            });
          }}
          setDestination={setDestination}
        />
      )}
    </div>
  );
};
