import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { CookieBanner } from 'app/views/Notifications/CookieBanner';
import PusherConfig from 'config/pusher';
import { getCookie, setCookie } from 'handlers/cookie.handler';
import { PusherDataHandler } from 'handlers/pusher/pusher-data.handler';
import { ChannelName, EventName } from 'handlers/pusher/pusher.enums';
import { selectActiveSiteAndCharger, selectPublicSites } from 'services/sites/selectors';

import { Footer } from './Footer';
import { Header } from './Header';

export type OutletContext = {
  initAuth: boolean;
  setInitAuth: (value: boolean) => void;
  isSomeDialogOpen: boolean;
  setIsSomeDialogOpen: (value: boolean) => void;
};

export const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const browserCookie = getCookie('cu_cookiedone');

  const activeSiteAndCharger = useSelector(selectActiveSiteAndCharger);
  const allPublicSites = useSelector(selectPublicSites);

  const [cookiesConsented, setCookiesConsented] = useState(!!browserCookie);
  const [initAuth, setInitAuth] = useState(undefined);
  const [isSomeDialogOpen, setIsSomeDialogOpen] = useState(false);

  const currentView = location.pathname.split('/')[1];
  const sites = allPublicSites.filter((aps) => !!aps.devices?.length);

  const dispatchActions = (actions: string[]) => {
    actions?.forEach((action) => dispatch(action));
  };

  useEffect(() => {
    if (!initAuth && activeSiteAndCharger) {
      navigate(`/sites?site=${activeSiteAndCharger.site}&device=${activeSiteAndCharger.device}`);
    }
  }, [initAuth]);

  useEffect(() => {
    if (cookiesConsented) {
      setCookie('cu_cookiedone', 'true', { expires: 365 });
    }
    const pusher = PusherConfig.getInstance();
    const subscriptionChannel = pusher.subscribe(ChannelName.PUBLIC);
    subscriptionChannel.bind(EventName.PUBLIC, (data: any) => {
      const handlerPusherData = new PusherDataHandler(data);
      const actions = handlerPusherData.getActions();
      dispatchActions(actions);
    });
    return () => {
      pusher.unsubscribe(ChannelName.PUBLIC);
    };
  }, [cookiesConsented]);

  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      <Header
        className={currentView === 'sites' && sites.length ? 'sm:hidden' : 'hidden sm:block'}
      />
      <div
        className={`${
          currentView !== 'sites' || !sites.length
            ? 'max-w-7xl mx-auto p-4 pt-5 sm:px-6 lg:px-8 h-full w-full'
            : 'h-full w-full'
        }`}
      >
        <Outlet
          context={{
            initAuth,
            setInitAuth,
            isSomeDialogOpen,
            setIsSomeDialogOpen,
          }}
        />
      </div>
      <Footer
        footerNotificationClassName={
          currentView === 'sites'
            ? 'sm:max-w-[400px]'
            : 'max-w-7xl left-1/2 transform -translate-x-1/2'
        }
      />
      {!cookiesConsented && (
        <CookieBanner
          onClose={() => setCookiesConsented(true)}
          className={`
            ${currentView === 'sites' && sites.length ? 'sm:left-96' : 'mt-10'}
          `}
        />
      )}
    </Box>
  );
};
