import { isNumber } from 'lodash';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';
import moment from 'moment';

import getMonetaryRevenue from './get-monetary-revenue';

export class TransactionHandler {
  transaction?: Transaction;

  constructor(transaction: Transaction) {
    this.transaction = transaction;
  }

  getAmount(toFixed?: number) {
    const { amount } = this.transaction ?? ({} as Transaction);
    let returnAmount;

    if (isNumber(amount)) {
      returnAmount = amount / 1000;

      if (toFixed) {
        returnAmount = returnAmount.toFixed(toFixed);
      }
    } else {
      returnAmount = '0';
    }

    return `${returnAmount} kWh`;
  }

  getMonetaryRevenueWithVAT(toFixed?: number) {
    const { monetaryRevenueWithVAT } = this.transaction ?? ({} as Transaction);
    const parsedMonetaryRevenue = getMonetaryRevenue(monetaryRevenueWithVAT ?? 0);
    let returnValue;
    if (parsedMonetaryRevenue) {
      if (toFixed) {
        returnValue = parsedMonetaryRevenue.toFixed(toFixed);
      } else {
        returnValue = parsedMonetaryRevenue;
      }
    } else {
      returnValue = '0,00';
    }

    return `${returnValue} €`;
  }

  getDuration() {
    const { endTime, startTime } = this.transaction ?? ({} as Transaction);
    const durationMs = (endTime ? moment(endTime) : moment()).diff(startTime);

    return `${moment.duration(durationMs).hours()}h ${moment.duration(durationMs).minutes()}m`;
  }

  getTransactionDevice(sites: Site[]) {
    const deviceUuid = this.transaction?.deviceUuid;
    return sites.flatMap((site) => site.devices).find((device) => device.deviceUuid === deviceUuid);
  }

  getParkingCost(toFixed?: number) {
    const { parkingCost } = this.transaction ?? ({} as Transaction);
    return parkingCost ? `${parkingCost.toFixed(toFixed ?? 2)} €` : '-';
  }

  getReservationCost(toFixed: number | undefined) {
    const { reservationCost } = this.transaction ?? ({} as Transaction);
    return reservationCost ? `${reservationCost.toFixed(toFixed ?? 2)} €` : '-';
  }

  getTotalCost(toFixed?: number) {
    const fixed = toFixed ?? 2;
    const chargingCost = parseFloat(this.getMonetaryRevenueWithVAT(fixed));
    const parkingCost = this.getParkingCostAsNumber(fixed);
    const reservationCost = this.getReservationCostAsNumber(fixed);
    const totalCost = chargingCost + parkingCost + reservationCost;
    return totalCost > 0 ? `${totalCost.toFixed(fixed)} €` : '-';
  }

  getParkingCostAsNumber(toFixed?: number): number {
    const { parkingCost } = this.transaction ?? ({} as Transaction);
    return parkingCost ? parseFloat(parkingCost.toFixed(toFixed ?? 2)) : 0;
  }

  getReservationCostAsNumber(toFixed?: number): number {
    const { reservationCost } = this.transaction ?? ({} as Transaction);
    return reservationCost ? parseFloat(reservationCost.toFixed(toFixed ?? 2)) : 0;
  }
}
