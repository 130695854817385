import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { datadogLogs } from '@datadog/browser-logs';
import { ThemeProvider } from '@mui/material';

import { Router } from 'app/Router';
import { Spinner } from 'components/atoms/Spinner';
import { some, toLower } from 'lodash';
import apiSlice from 'services/api';
import { modifyPortalConfig } from 'services/portal/actions';
import { selectPortalConfig } from 'services/portal/selectors';
import store from 'services/store';

import { theme } from './theme';
import { setTheme } from './themeSettings';

const ProviderChild = () => {
  const dispatch = useDispatch();
  const { colors, title } = useSelector(selectPortalConfig);
  const customerDomain = window.location.hostname.split('.')[0];
  const { isError } = apiSlice.useGetPortalConfigQuery(customerDomain);

  useEffect(() => {
    setTheme(colors);
  }, [colors]);

  useEffect(() => {
    if (isError) {
      dispatch(modifyPortalConfig);
    }
  }, [isError]);

  if (!colors) {
    return (
      <div className="mt-20">
        <Spinner />
      </div>
    );
  }

  return (
    <BrowserRouter>
      {!!title && (
        <Helmet>
          <title>{`${title} Public Charging`}</title>
          <meta name="description" content="Public Charging with VOOL chargers." />
          <meta property="og:title" content={`${title} Public Charging`} />
          <meta property="og:url" content={`${toLower(title)}.vool.com`} />
          <meta property="og:description" content="Public Charging with VOOL chargers." />
        </Helmet>
      )}
      <Router />
    </BrowserRouter>
  );
};

export const App = () => {
  useEffect(() => {
    if (
      some([
        process.env.REACT_APP_CONF_FILE === 'staging',
        process.env.REACT_APP_CONF_FILE === 'production',
      ])
    ) {
      datadogLogs.init({
        clientToken: 'pub85969a68cad312aa57365aafad9d1617',
        site: 'datadoghq.eu',
        env: process.env.REACT_APP_CONF_FILE,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error'],
        sampleRate: 100,
        service: 'customer-portal:web',
        silentMultipleInit: true,
      });
    }
  }, []);

  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ProviderChild />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};
