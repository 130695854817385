import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';

import accountIcon from 'app/images/icons/account.svg';
import batteryIcon from 'app/images/icons/battery.svg';
import billingIcon from 'app/images/icons/billing.svg';
import hamburgerMenu from 'app/images/icons/hamburgerMenu.svg';
import logoutIcon from 'app/images/icons/logout.svg';
import historyIcon from 'app/images/icons/time.svg';
import { AuthModal } from 'app/views/Login/AuthModal';
import { SendFeedbackButton } from 'components/feedback/SendFeedbackButton';
import { HeaderMenu } from 'components/menus/HeaderMenu';
import PusherConfig from 'config/pusher';
import { getCookie } from 'handlers/cookie.handler';
import { PusherDataHandler } from 'handlers/pusher/pusher-data.handler';
import { EventName } from 'handlers/pusher/pusher.enums';
import { useLogout } from 'hooks/use-logout';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';

import { OutletContext } from './Layout';
import { Logo } from './Logo';

type HeaderProps = {
  className?: string;
};

export const Header = ({ className }: HeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setInitAuth, isSomeDialogOpen } = useOutletContext<OutletContext>() || {};
  const location = useLocation();
  const actualPath = location.pathname;

  const token = getCookie('cu_token');
  const temporaryToken = getCookie('cu_temporaryToken');
  const user = useSelector(selectUser);

  const [authOpen, setAuthOpen] = useState(false);
  const [skipActiveSession, setSkipActiveSession] = useState(true);

  const handleAuthOpen = isSomeDialogOpen ? setInitAuth : setAuthOpen;
  const logout = useLogout();

  const { isLoading } = apiSlice.useSelfQuery(null, {
    skip: !token && !temporaryToken,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  apiSlice.useActiveTransactionQuery(undefined, {
    skip: skipActiveSession,
    refetchOnMountOrArgChange: true,
  });

  apiSlice.useGetActiveParkingAndReservationQuery(undefined, {
    skip: skipActiveSession,
    refetchOnMountOrArgChange: true,
  });

  const dispatchActions = (actions: string[]) => {
    if (actions?.length) {
      actions.forEach((action) => dispatch(action));
    }
  };

  const initiateLogin = () => {
    handleAuthOpen(true);
    trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_LOGIN, {
      ...getAmplitudeEventPropertiesForUser(user),
      startingPoint: 'mainMenu',
    });
  };

  useEffect(() => {
    const privateChannels = user?.pusherChannels ?? [];
    const pusher = PusherConfig.getInstance(token);

    if (user) {
      privateChannels.forEach((privateChannel) => {
        const subscriptionChannel = pusher.subscribe(privateChannel);
        subscriptionChannel.bind(EventName.PRIVATE, (data: any) => {
          const pusherDataHandler = new PusherDataHandler(data);
          const actions = pusherDataHandler.getActions();
          dispatchActions(actions);
        });
      });
    }

    return () => {
      privateChannels.forEach((privateChannel) => pusher.unsubscribe(privateChannel));
    };
  }, [token, user]);

  useEffect(() => {
    setSkipActiveSession(!user);
  }, [user]);

  return (
    <div
      className={`${className} relative z-20 w-full bg-app-header sm:pt-2 shadow-md sm:shadow-none`}
    >
      <header className="sm:border-b-0">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6" aria-label="Top">
          <div className="w-full flex items-center justify-between h-14">
            <div className="flex items-center">
              <Logo navigateTo="/sites" />
            </div>
            <div className="flex ml-auto pr-2">
              <SendFeedbackButton />
            </div>
            <div className="flex items-center text-app-header-link">
              {user?.name && (
                <div className="text-sm pl-2.5">
                  {`${t('hi, [user_name]', 'Hi, {{name}}', { name: user?.name.split(' ')[0] })}`}
                </div>
              )}
              {user && (
                <HeaderMenu
                  menuItems={[
                    {
                      title: t('account', 'Account'),
                      href: '/account',
                      icon: accountIcon,
                      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_ACCOUNT_VIEW,
                      hidden: !user?.name,
                    },
                    {
                      title: t('sites', 'Sites'),
                      href: '/sites',
                      icon: batteryIcon,
                      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_SITES_VIEW,
                    },
                    {
                      title: t('chargingHistory', 'Charging history'),
                      href: '/charge-history',
                      icon: historyIcon,
                      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_CHARGING_HISTORY_VIEW,
                    },
                    {
                      title: t('billing', 'Billing'),
                      href: '/billing',
                      icon: billingIcon,
                      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_BILLING_VIEW,
                    },
                    {
                      title: t('logOut', 'Log out'),
                      onClick: () => {
                        setSkipActiveSession(true);
                        logout();
                      },
                      icon: logoutIcon,
                      hidden: !user?.name,
                    },
                  ].filter((menuItem) => !menuItem.hidden)}
                  selectedItem={actualPath}
                  icon={hamburgerMenu}
                />
              )}
              {!user && !isLoading && (
                <>
                  <button
                    className="text-base font-semibold bg-vool-green text-white px-6 py-2 rounded-2xl"
                    type="button"
                    onClick={initiateLogin}
                  >
                    {t('logIn', 'Login')}
                  </button>
                  <HeaderMenu icon={hamburgerMenu} />
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
      <AuthModal
        open={authOpen}
        closeModal={() => {
          handleAuthOpen(false);
        }}
      />
    </div>
  );
};
