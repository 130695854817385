import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { format } from 'date-fns';
import { TransactionHandler } from 'handlers/transaction.handler';
import { Transaction } from 'models/transaction';
import apiSlice from 'services/api';
import { selectInvoices } from 'services/billing/selectors';
import { selectPublicSites } from 'services/sites/selectors';

import { ChargerIcon } from '../PublicSites/ChargerIcon';
import { TransactionModal } from './TransactionModal';

type ChargingHistoryListProps = {
  transactions: Transaction[];
};

export const ChargingHistoryList = ({ transactions }: ChargingHistoryListProps) => {
  const invoices = useSelector(selectInvoices);
  apiSlice.useGetInvoicesQuery();
  const sites = useSelector(selectPublicSites);
  // TODO: Sites should be loaded when user comes to the site and account changes
  const { isLoading } = apiSlice.usePublicSitesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();

  return (
    <Box>
      {transactions.map((transaction, index) => {
        const handler = new TransactionHandler(transaction);

        /* TODO: Adding index is a hack because multiple transactions with same id
        can be returned with current payment logic */
        return (
          <Box
            key={`${transaction.transactionId}-${index}`}
            display="flex"
            alignItems="center"
            gap={2}
            py={1.5}
            className="border-b border-zinc cursor-pointer"
            onClick={() => setSelectedTransaction(transaction)}
          >
            <Box width="24px" height="24px">
              <ChargerIcon stroke="#999898" />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography variant="p16">{transaction.siteName}</Typography>
              <Box>
                <Typography variant="p14" color="gray" noWrap>
                  {format(new Date(transaction.startTime), 'dd MMM, HH:mm')}
                  {transaction.carBrand && transaction.carModel
                    ? ` • ${transaction.carBrand} ${transaction.carModel}`
                    : transaction.carName && ` • ${transaction.carName}`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="p16">{handler.getTotalCost(2)}</Typography>
          </Box>
        );
      })}
      {selectedTransaction && !isLoading && (
        <TransactionModal
          transaction={selectedTransaction}
          invoice={invoices.find((invoice) => invoice.id === selectedTransaction.stripeInvoiceId)}
          site={sites.find((site) => site.uuid === selectedTransaction.siteUuid)}
          onClose={() => setSelectedTransaction(undefined)}
        />
      )}
    </Box>
  );
};
