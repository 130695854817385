import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Transaction, TransactionStatus } from 'models/transaction';
import api from 'services/api';

import { removeCredentials } from '../auth';
import { clearTransaction, updateTransactionFromPusher } from './actions';

interface TransactionsState {
  transactions: {
    list: Transaction[];
    lastTransactions: Transaction[];
    period?: string;
  };
  activeTransaction?: Transaction;
}

const initialState: TransactionsState = {
  transactions: {
    list: [],
    lastTransactions: [],
  },
};

const setTransactionsToState = (
  state: TransactionsState,
  { payload }: PayloadAction<{ transactions: Transaction[] }>,
) => {
  state.transactions.list = payload.transactions;
};

const setLastTransactionsState = (
  state: TransactionsState,
  { payload }: PayloadAction<{ transactions: Transaction[] }>,
) => {
  // TODO: This logic should be checked if it works as expected
  const sortedData = payload.transactions
    .filter((s) => !!s.startTime)
    .sort((a, b) => {
      const aDate = new Date(a.updateTime ?? 0);
      const bDate = new Date(b.updateTime ?? 0);
      return aDate.getTime() - bDate.getTime();
    });
  state.transactions.lastTransactions = sortedData;
};

const setActiveTransactionState = (
  state: TransactionsState,
  { payload }: PayloadAction<{ transaction: Transaction }>,
) => {
  if (Object.keys(payload.transaction).length !== 0) {
    state.activeTransaction = payload.transaction;
  } else {
    state.activeTransaction = undefined;
  }
};

const emptyActiveTransactionState = (state: TransactionsState) => {
  state.activeTransaction = undefined;
};

const updateActiveTransactionFromPusher = (
  state: TransactionsState,
  { payload }: PayloadAction<Transaction>,
) => {
  if (state?.activeTransaction?.userUuid !== payload.userUuid) return;
  if (payload?.status === TransactionStatus.Ended) {
    state.activeTransaction = undefined;
  } else {
    state.activeTransaction = {
      ...state.activeTransaction,
      ...payload,
    };
  }
};

const deleteActiveTransaction = (state: TransactionsState) => {
  state.activeTransaction = undefined;
};

const clearState = () => initialState;

export const dataSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionsPeriod: (state: TransactionsState, { payload }: PayloadAction<string>) => {
      state.transactions.period = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeCredentials, clearState)
      .addCase(updateTransactionFromPusher, updateActiveTransactionFromPusher)
      .addCase(clearTransaction, clearState)
      .addMatcher(api.endpoints.customerTransactions.matchFulfilled, setTransactionsToState)
      .addMatcher(api.endpoints.lastTransactions.matchFulfilled, setLastTransactionsState)
      .addMatcher(api.endpoints.activeTransaction.matchFulfilled, setActiveTransactionState)
      .addMatcher(api.endpoints.startCharging.matchFulfilled, setActiveTransactionState)
      .addMatcher(api.endpoints.stopCharging.matchFulfilled, emptyActiveTransactionState)
      .addMatcher(api.endpoints.activeTransaction.matchFulfilled, setActiveTransactionState)
      .addMatcher(api.endpoints.cancelPendingTransaction.matchFulfilled, deleteActiveTransaction);
  },
});

export const { setTransactionsPeriod } = dataSlice.actions;

export default dataSlice.reducer;
