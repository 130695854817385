import { ApiEndpointBuilder } from 'services/api/endpoints';

import { DeviceStatusResponse } from './responses';

export const buildDevicesEndpoints = (builder: ApiEndpointBuilder) => ({
  getDeviceStatus: builder.query<DeviceStatusResponse, string>({
    query: (deviceUuid) => ({
      url: `/devices/public/status/${deviceUuid}`,
      method: 'GET',
    }),
  }),
});
