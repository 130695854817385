import { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import { Input, InputProps } from 'components/atoms/Input';
import countries from 'config/countries.json';

type CountryAutocompleteProps = InputProps & {
  onChange: (value: string | undefined) => void;
  value: string;
};

export const CountryAutocomplete = ({
  onChange,
  value: savedValue,
  ...props
}: CountryAutocompleteProps) => {
  const [value, setValue] = useState(
    countries.find((c) => c.code === savedValue.toLowerCase())?.name ?? '',
  );
  const onValueChange = (val: any) => {
    const countryCode = countries.find((c) => c.name === val)?.code;
    setValue(val);

    if (countryCode) {
      onChange(countryCode);
    } else {
      onChange(undefined);
    }
  };
  const onBlur = () => {
    const countryCode = countries.find((c) => c.name === value)?.code;

    if (!countryCode) {
      setValue('');
    }
  };
  const ref = useOnclickOutside(() => onBlur);

  useEffect(() => {
    if (savedValue) {
      onValueChange(countries.find((c) => c.code === savedValue.toLowerCase())?.name);
    }
  }, [savedValue]);

  return (
    <div ref={ref} className="grid grid-cols-1">
      <Autocomplete
        getItemValue={(item) => item.name}
        items={countries}
        shouldItemRender={(i, v) => i.name.toLowerCase().indexOf(v.toLowerCase()) > -1}
        renderItem={(item, isHighlighted) => (
          <div key={item.code} className={`${isHighlighted ? 'bg-gray-100' : ''} px-2 py-1`}>
            {item.name}
          </div>
        )}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        onSelect={onValueChange}
        renderInput={(prps) => (
          <Input
            className="w-full"
            {...props}
            inputProps={{
              ...prps,
            }}
          />
        )}
      />
    </div>
  );
};
