import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import api from 'services/api';

import ampherConfig from '../../themes/ampher.json';
import { modifyPortalConfig } from './actions';

interface PortalState {
  config: any;
  isChargerOpen: boolean;
  bottomSheetHeight?: number;
}

const setPortalConfig = (state: PortalState, { payload }: PayloadAction<{ settingsJson: any }>) => {
  state.config = payload.settingsJson || ampherConfig;
};

const initialState: PortalState = {
  config: {},
  isChargerOpen: false,
};

export const portalSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    chargerDetailsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isChargerOpen = payload;
    },
    updateBottomSheetHeight: (state, { payload }: PayloadAction<number | undefined>) => {
      state.bottomSheetHeight = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(modifyPortalConfig, setPortalConfig);
    builder.addMatcher(api.endpoints.getPortalConfig.matchFulfilled, setPortalConfig);
  },
});

export const { chargerDetailsOpen, updateBottomSheetHeight } = portalSlice.actions;

export default portalSlice.reducer;
