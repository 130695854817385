import { createSelector } from 'reselect';
import { RootState } from 'services/store';

export const selectSlice = (state: RootState) => state.portal;

export const selectPortalConfig = createSelector(selectSlice, (slice) => slice.config);
export const selectPortalLogo = createSelector(selectSlice, (slice) => slice?.config?.media?.logo);
export const selectPortalFooterLinks = createSelector(
  selectSlice,
  (slice) => slice?.config?.links?.footer,
);
export const selectIsChargerOpen = createSelector(selectSlice, (slice) => slice.isChargerOpen);
export const selectBottomSheetHeight = createSelector(
  selectSlice,
  (slice) => slice.bottomSheetHeight,
);
