import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Transition } from '@headlessui/react';
import { Box } from '@mui/material';

import { VehicleCard } from 'app/views/Vehicles/VehicleCard';
import { useSnackbar } from 'components/alerts/MessageToast';
import { EnergyPrice } from 'components/energyPrice';
import { ThankYouModal } from 'components/modals/chargingSummary/ThankYouModal';
import { getEvseActiveCharge, getEvseStatus } from 'handlers/device/device.handler';
import { OcppStatus } from 'handlers/device/ocpp-status.enum';
import { SiteHandler } from 'handlers/site.handler';
import { useLogout } from 'hooks/use-logout';
import { isEmpty, some } from 'lodash';
import { Evse } from 'models/device';
import { Site } from 'models/site';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForDevice } from 'services/amplitude/data';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';
import { selectPaymentMethods } from 'services/billing/selectors';
import { clearParkingAndReservation } from 'services/parkingAndReservations/actions';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { chargerDetailsOpen } from 'services/portal';
import { clearTransaction } from 'services/transactions/actions';
import { selectActiveTransaction } from 'services/transactions/selectors';
import { selectDefaultVehicle } from 'services/vehicles/selectors';

import { ChargerActionContainer } from './ChargerActionContainer';
import { ChargerDetailsCard } from './ChargerDetailsCard';
import { ChargerHeader } from './ChargerHeader';
import { PaymentMethodCard } from './PaymentMethodCard';
import { StripeSessionComponent } from './StripeSessionComponent';

type SlideBarChargerViewProps = {
  site: Site;
  device: Evse;
  closeSlideBar: () => void;
};

export const SlideBarChargerView = ({ site, device, closeSlideBar }: SlideBarChargerViewProps) => {
  const { uuid: deviceUuid, connectorId } = device;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showSnackbar, Snackbar } = useSnackbar();
  const user = useSelector(selectUser);
  const paymentMethods = useSelector(selectPaymentMethods);
  const activeTransaction = useSelector(selectActiveTransaction);

  const activeReservation = useSelector(selectActiveReservation);
  const defaultVehicle = useSelector(selectDefaultVehicle);
  // TODO: Needs proper type
  const [lastChargingData, setLastChargingData] = useState<any>(undefined);
  const siteHandler = new SiteHandler(site);
  const status = getEvseStatus(device, activeTransaction, activeReservation);
  const activeCharge = getEvseActiveCharge(device, activeTransaction);
  const defaultPaymentMethod = paymentMethods.length
    ? paymentMethods.find((pm) => !!pm.defaultFlag) ?? paymentMethods[0]
    : undefined;

  const [startCharging, { isLoading: isStartLoading }] = apiSlice.useStartChargingMutation();
  const [stopCharging, { isLoading: isStopLoading, isSuccess: isStopSuccess }] =
    apiSlice.useStopChargingMutation();
  const buttonLoading = some([isStartLoading, isStopLoading]);
  const { refetch: refetchActiveTransaction } = apiSlice.useActiveTransactionQuery(undefined);
  const [initStripeSession] = apiSlice.useAddPublicPaymentMethodMutation();
  const [initUserStripeSession] = apiSlice.useAddPaymentMethodMutation();

  const logout = useLogout();

  const startChargeHandler = async () => {
    dispatch(clearParkingAndReservation());
    try {
      const response = await startCharging({
        deviceUuid,
        connectorId,
        carUuid: defaultVehicle?.uuid,
      });
      // TODO: Error handling needs proper rework
      if ('error' in response) {
        trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_CHARGING, {
          ...getAmplitudeEventPropertiesForDevice({ device, site, user }),
          status: 'Failure',
        });

        const chargingError = response.error as any;
        throw new Error(chargingError?.data?.message);
      } else {
        showSnackbar(`${t('chargeStartedAlertMessage', 'Your charge was successfully started.')}`);
      }
    } catch (err: any) {
      showSnackbar(err?.message || 'Something went wrong');
    }
  };

  const stopChargeHandler = async () => {
    try {
      // TODO: This needs to be all typed and rewritten
      if (!activeTransaction?.deviceSerialNumber)
        throw Error('No active transaction found in stopChargeHandler');
      const response = await stopCharging({
        deviceSerialNumber: activeTransaction.deviceSerialNumber,
        connectorId,
      });

      if ('error' in response) {
        trackAmplitudeEvent(AMPLITUDE_EVENTS.STOP_CHARGING, {
          ...getAmplitudeEventPropertiesForDevice({ device, site, user }),
          status: 'Failure',
        });

        const chargingError = response.error as any;
        throw new Error(chargingError?.data?.message);
      }

      if (!user?.name && !response?.data?.parking) {
        logout();
      }

      setLastChargingData({
        hasParkingData: !!response?.data?.parking,
        hasReservationData: !!response?.data?.reservation,
        lastCharging: response?.data?.charging,
        lastParking: response?.data?.parking,
        lastReservation: response?.data?.reservation,
      });
    } finally {
      dispatch(clearTransaction());
    }
  };

  const startPayment = () => {
    const initSession = user ? initUserStripeSession : initStripeSession;
    initSession({
      returnUrl: `/sites?site=${site.uuid}&device=${device.uuid}`,
    });
  };

  useEffect(() => {
    if (status === OcppStatus.CHARGING && activeTransaction?.transactionId?.includes?.('pending')) {
      refetchActiveTransaction();
    }

    if (status === OcppStatus.CHARGING && activeCharge) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_CHARGING, {
        ...getAmplitudeEventPropertiesForDevice({ device, site, user }),
        status: 'Success',
      });
    }
  }, [status]);

  useEffect(() => {
    if (isStopSuccess) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.STOP_CHARGING, {
        ...getAmplitudeEventPropertiesForDevice({ device, site, user }),
        status: 'Success',
      });
    }
  }, [isStopSuccess]);

  useEffect(() => {
    dispatch(chargerDetailsOpen(true));

    return () => {
      dispatch(chargerDetailsOpen(false));
    };
  }, []);

  return (
    <Transition.Root show>
      <div className="fixed top-0 right-0 pointer-events-auto z-30 h-full sm:w-auto w-full">
        <Transition.Child
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          className="h-full w-full"
        >
          <Box
            display="flex"
            flexDirection="column"
            overflow="auto"
            width={{ xs: '100%', sm: '408px' }}
            height={1}
            className="bg-white shadow-xl"
          >
            <ChargerHeader site={site} device={device} closeSlideBar={closeSlideBar} />
            {/* scrollable charger content section */}
            <div className="flex h-full flex-col w-full overflow-y-auto">
              {siteHandler.isMarketPrice() && (
                <div className="px-2">
                  <EnergyPrice title="" borderless full siteUuid={site.uuid} />
                </div>
              )}
              <ChargerDetailsCard siteHandler={siteHandler} maxPower={device.maxPowerKw} />
              {user && <VehicleCard />}

              {!!defaultPaymentMethod && (
                <PaymentMethodCard defaultPaymentMethod={defaultPaymentMethod} />
              )}
            </div>
            {Snackbar}
            {/* CTA buttons section */}
            <ChargerActionContainer
              site={site}
              device={device}
              startChargeHandler={startChargeHandler}
              stopChargeHandler={stopChargeHandler}
              buttonLoading={buttonLoading}
              startPayment={startPayment}
              onMessage={(message) => showSnackbar(message)}
            />
          </Box>
        </Transition.Child>
      </div>
      {!activeCharge && !isEmpty(device) && (
        <StripeSessionComponent startCharge={startChargeHandler} />
      )}
      {lastChargingData === 100 && (
        <ThankYouModal
          closeModal={() => setLastChargingData(undefined)}
          defaultPaymentMethod={defaultPaymentMethod}
          userName={user?.name ?? ''}
          lastChargingData={lastChargingData}
        />
      )}
    </Transition.Root>
  );
};
