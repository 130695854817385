import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'components/atoms/Button';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { TransactionHandler } from 'handlers/transaction.handler';
import { isEmpty } from 'lodash';
import { Device } from 'models/device';
import { Site } from 'models/site';
import { User } from 'models/user';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForDevice } from 'services/amplitude/data';
import apiSlice from 'services/api';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { CancelReservationModal } from './CancelReservationModal';
import { ReservationModal } from './ReservationModal';

type ReserveChargerButtonProps = {
  device: Device;
  user?: User;
  status?: string;
  site?: Site;
  onMessage: (message: string) => void;
  hasPaymentMethods: boolean;
  startPayment: () => void;
};

export const ReserveChargerButton = ({
  device,
  user,
  status,
  site,
  onMessage,
  hasPaymentMethods,
  startPayment,
}: ReserveChargerButtonProps) => {
  const transaction = useSelector(selectActiveTransaction);
  const activeTransaction = transaction && new TransactionHandler(transaction);

  const deviceUuid = device.uuid;
  const { t } = useTranslation();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [reserveDevice, { error, isLoading: reserveLoading }] = apiSlice.useReserveDeviceMutation();
  const [cancelReservation, { isLoading: cancelLoading }] =
    apiSlice.useCancelDeviceReservationMutation();
  const reservationObject = useSelector(selectActiveReservation);
  const buttonLoading = reserveLoading || cancelLoading;
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const handleButtonClick = async () => {
    if (!user || !hasPaymentMethods) {
      setIsReservationModalOpen(true);
    } else if (deviceUuid === reservationObject?.deviceUuid) {
      setCancelModalOpen(true);
    } else {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.START_RESERVATION, {
        ...getAmplitudeEventPropertiesForDevice({ device, user, site }),
        startingPoint: 'deviceDetails',
      });

      await reserveDevice(deviceUuid);
      // TODO: Success or error toast here?
    }
  };

  useEffect(() => {
    if (error) {
      // TODO: Error handling needs to be fixed properly to not use any
      const queryError = error as any;
      onMessage(queryError?.data?.message || queryError?.error || 'Something went wrong');
    }
  }, [error]);

  return (
    <div>
      {site?.settings?.chargerReservation?.isEnabled && (
        <div className="relative">
          <Button
            radiusClass="rounded-full"
            className="relative w-full h-14 text-base font-semibold py-0"
            color="bg-vool-gray-200"
            textColor="raisingblack"
            fontSize="text-base"
            fontWeight="font-semibold"
            shadow="shadow-none"
            onClick={handleButtonClick}
            disabled={
              (!isEmpty(reservationObject) && deviceUuid !== reservationObject.deviceUuid) ||
              (status !== 'Available' && deviceUuid !== reservationObject?.deviceUuid) ||
              activeTransaction !== undefined
            }
          >
            {deviceUuid === reservationObject?.deviceUuid
              ? t('cancelReserve', 'Cancel reservation')
              : t('reserveCharger', 'Reserve charger')}
          </Button>
          {buttonLoading && <OverflowSpinner size={6} />}
        </div>
      )}
      <CancelReservationModal
        isOpen={cancelModalOpen}
        closeModal={() => setCancelModalOpen(false)}
        cancelReservation={() => {
          trackAmplitudeEvent(AMPLITUDE_EVENTS.CANCEL_RESERVATION, {
            ...getAmplitudeEventPropertiesForDevice({ device, user, site }),
            startingPoint: 'deviceDetails',
          });

          return cancelReservation(deviceUuid);
        }}
        onCancel={(message) => onMessage(message)}
      />
      <ReservationModal
        openReservationModal={isReservationModalOpen}
        closeReservationModal={() => setIsReservationModalOpen(false)}
        hasPaymentMethods={hasPaymentMethods}
        user={user}
        startPayment={startPayment}
      />
    </div>
  );
};
