import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEvseActiveCharge } from 'handlers/device/device.handler';
import { Evse } from 'models/device';
import { Site } from 'models/site';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { SinglePublicCharger } from '../Charger/SinglePublicCharger';

type ChargersListingProps = {
  title: string;
  deviceList: Evse[];
  site: Site;
  setActiveDeviceIfAvailable: (device: Evse) => void;
  activeDeviceUuid?: string;
  siteDeviceMaxPower: number;
  siteMaxPowerLoading: boolean;
};

export const ChargersListing = ({
  title,
  deviceList,
  site,
  setActiveDeviceIfAvailable,
  activeDeviceUuid,
  siteDeviceMaxPower = 22,
  siteMaxPowerLoading,
}: ChargersListingProps) => {
  const activeTransaction = useSelector(selectActiveTransaction);
  const isListHidden = !deviceList.length;

  const [devicesOrdered, setDevicesOrdered] = useState(deviceList);

  const chargersOrderedByActiveCharge = (evses: Evse[]) =>
    [...evses].sort((a, b) => {
      const aHasActiveCharge = getEvseActiveCharge(a, activeTransaction);
      const bHasActiveCharge = getEvseActiveCharge(b, activeTransaction);
      if (aHasActiveCharge && !bHasActiveCharge) {
        return -1;
      }
      if (!aHasActiveCharge && bHasActiveCharge) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    setDevicesOrdered(chargersOrderedByActiveCharge(deviceList));
  }, [deviceList, activeTransaction]);

  return (
    <div className={`mb-6 space-y-2 ${isListHidden ? 'hidden' : ''}`}>
      {!!devicesOrdered.length && <span className="text-lg font-semibold">{title}</span>}
      <div className="space-y-4">
        {devicesOrdered.map((device) => (
          <SinglePublicCharger
            site={site}
            key={device.evseId}
            selectActiveDevice={setActiveDeviceIfAvailable}
            active={activeDeviceUuid === device.uuid}
            device={device}
            disabled={!!activeDeviceUuid && activeDeviceUuid !== device.uuid}
            siteDeviceMaxPower={siteDeviceMaxPower}
            siteMaxPowerLoading={siteMaxPowerLoading}
          />
        ))}
      </div>
    </div>
  );
};
