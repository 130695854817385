import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Device } from 'models/device';
import { Site } from 'models/site';
import api from 'services/api';

import {
  updateActiveSite,
  updateActiveSiteAndCharger,
  updateActiveSiteByUuid,
  updatePublicDeviceFromPusher,
  updateSitesDistance,
} from './actions';

export type SiteHourlyPrices = Record<string, number | null>;
export type SiteDeviceMaxPowers = Record<string, number>;

interface HourlyPricesPayload {
  siteUuid: string;
  pricesPerHour: SiteHourlyPrices;
}

export type ActiveSiteAndChargerPayload = { site: string; device: string } | undefined;

interface SitesState {
  publicSites: Site[];
  activeSiteAndCharger?: ActiveSiteAndChargerPayload;
  siteDeviceMaxPowers: SiteDeviceMaxPowers;
  hourlyPrices: Record<string, SiteHourlyPrices>;
  activeSite?: Site;
}

const updatePublicDeviceBySerialNumber = (
  state: SitesState,
  { payload }: PayloadAction<{ device?: Device }>,
) => {
  const deviceSerialNumber = payload.device?.serialNumber || payload.device?.deviceSerialNumber;
  const siteIndex = state.publicSites.findIndex(({ devices }) =>
    devices.some(({ serialNumber }) => serialNumber === deviceSerialNumber),
  );
  if (siteIndex !== -1) {
    const deviceIndex = state.publicSites[siteIndex].devices.findIndex(
      ({ serialNumber }) => serialNumber === deviceSerialNumber,
    );

    const deviceUpdated = {
      ...state.publicSites[siteIndex].devices[deviceIndex],
      ...payload.device,
    };
    state.publicSites[siteIndex].devices[deviceIndex] = deviceUpdated;
  }
};

const setPublicSites = (state: SitesState, { payload }: PayloadAction<{ sites: Site[] }>) => {
  state.publicSites = orderSitesByDistance(
    payload.sites.map((site) => {
      const siteFromPayload = state.publicSites.find((s) => s.uuid === site.uuid);
      if (siteFromPayload) {
        return {
          ...siteFromPayload,
          ...site,
          vatMultiplier: site.countryVat / 100 + 1,
        };
      }
      return site;
    }),
  );

  if (state.activeSiteAndCharger && !state.activeSite) {
    const { site } = state.activeSiteAndCharger;
    state.activeSite = state.publicSites.find(({ uuid }) => uuid === site);
  }
};

const setActiveSiteAndCharger = (
  state: SitesState,
  { payload }: PayloadAction<ActiveSiteAndChargerPayload>,
) => {
  state.activeSiteAndCharger = payload;
};

const setSiteDeviceMaxPower = (
  state: SitesState,
  { payload }: PayloadAction<{ data: { maxPower: number; uuid: string } }>,
) => {
  const { maxPower, uuid } = payload.data;
  state.siteDeviceMaxPowers[uuid] = maxPower;
};

const setHourlyPrices = (state: SitesState, { payload }: PayloadAction<HourlyPricesPayload>) => {
  state.hourlyPrices[payload.siteUuid] = Object.fromEntries(
    Object.entries(payload.pricesPerHour).filter(([, v]) => v != null),
  );
};

const setActiveSite = (state: SitesState, { payload }: PayloadAction<Site | undefined>) => {
  state.activeSite = payload;
};

const setActiveSiteByUuid = (state: SitesState, { payload }: PayloadAction<string>) => {
  state.activeSite = state.publicSites.find(({ uuid }) => uuid === payload);
};

const updateSitesDistanceAndOrder = (state: SitesState, { payload }: PayloadAction<Site[]>) => {
  const updatedSites = state.publicSites.map((site) => {
    const siteWithDistance = payload.find((s) => s.uuid === site.uuid);
    if (siteWithDistance) {
      site.distance = siteWithDistance.distance;
    }
    return site;
  });

  state.publicSites = orderSitesByDistance(updatedSites);
};

const getDistanceValue = (d: google.maps.Distance) => {
  const value = parseFloat(d.text.replace(/[^0-9.]/g, ''));
  const isKm = d.text.includes('km');
  return isKm ? value : value / 1000;
};

const orderSitesByDistance = (sites: Site[]) => {
  return sites.sort((a, b) => {
    const aDistance = a.distance ? getDistanceValue(a.distance) : Infinity;
    const bDistance = b.distance ? getDistanceValue(b.distance) : Infinity;
    return aDistance - bDistance;
  });
};

const initialState: SitesState = {
  publicSites: [],
  activeSiteAndCharger: undefined,
  siteDeviceMaxPowers: {},
  hourlyPrices: {},
  activeSite: undefined,
};

export const dataSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateActiveSiteAndCharger, setActiveSiteAndCharger);
    builder.addCase(updateSitesDistance, updateSitesDistanceAndOrder);
    builder.addCase(updatePublicDeviceFromPusher, updatePublicDeviceBySerialNumber);
    builder.addCase(updateActiveSite, setActiveSite);
    builder.addCase(updateActiveSiteByUuid, setActiveSiteByUuid);
    builder.addMatcher(api.endpoints.publicSites.matchFulfilled, setPublicSites);
    builder.addMatcher(api.endpoints.getSiteDeviceMaxPower.matchFulfilled, setSiteDeviceMaxPower);
    builder.addMatcher(api.endpoints.getSiteHourlyPrices.matchFulfilled, setHourlyPrices);
  },
});

export default dataSlice.reducer;
