import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SlideBarChargerView } from 'app/views/Charger/SlideBarChargerView';
import { createEvses, isEvseAvailable } from 'handlers/device/device.handler';
import { findIndex, partition } from 'lodash';
import { Device, Evse } from 'models/device';
import { Site } from 'models/site';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';
import { selectPaymentMethods, selectStripeSessionUrl } from 'services/billing/selectors';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { chargerDetailsOpen } from 'services/portal';
import { selectIsChargerOpen } from 'services/portal/selectors';
import { selectSiteDeviceMaxPowerByUuid } from 'services/sites/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { TryNowBox } from '../Login/TryNowBox';
import { ChargersListing } from './ChargerListing';

type SlideBarChargersProps = {
  devices: Device[];
  site: Site;
  siteMaxPowerLoading: boolean;
};

export const SlideBarChargers = ({ devices, site, siteMaxPowerLoading }: SlideBarChargersProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const evses: Evse[] = devices.flatMap((device) => createEvses(device));

  const user = useSelector(selectUser);
  const stripeSessionUrl = useSelector(selectStripeSessionUrl);
  const paymentMethods = useSelector(selectPaymentMethods);
  const activeTransaction = useSelector(selectActiveTransaction);
  const siteDeviceMaxPower = useSelector(selectSiteDeviceMaxPowerByUuid(site.uuid));
  const activeReservation = useSelector(selectActiveReservation);

  const [activeDeviceUuid, setActiveDeviceUuid] = useState<string>();
  const activeDevice = evses.find((d) => d.evseId === activeDeviceUuid);
  const isChargerOpen = useSelector(selectIsChargerOpen);

  const searchParams = new URLSearchParams(search);
  const urlSiteUuid = searchParams.get('site');
  const urlDeviceUuid = searchParams.get('device');
  const [availableDevices, unavailableDevices] = partition(evses, (evse) =>
    isEvseAvailable(evse, activeTransaction, activeReservation),
  );

  const [initStripeSession] = apiSlice.useAddPaymentMethodMutation();
  const { isLoading, refetch: refetchGetPaymentMethods } = apiSlice.useGetPaymentMethodsQuery();
  const [setDefault] = apiSlice.useSetPaymentMethodAsDefaultMutation();

  const setActiveDeviceIfAvailable = (device: Evse) => {
    const available = isEvseAvailable(device, activeTransaction, activeReservation);
    if (available) {
      setActiveDeviceUuid(device.evseId);
      dispatch(chargerDetailsOpen(true));
    }
  };

  const handleChangeSite = () => {
    if (urlSiteUuid !== site.uuid) {
      dispatch(chargerDetailsOpen(false));
      setActiveDeviceUuid(undefined);
      navigate({ search: '' });
    }
  };

  useEffect(handleChangeSite, [site.uuid]);

  useEffect(() => {
    if (stripeSessionUrl) {
      window.location.href = stripeSessionUrl;
    }
  }, [stripeSessionUrl]);

  useEffect(() => {
    if (!urlDeviceUuid) {
      return;
    }
    const activeDevice = evses.find((d) => d.evseId === urlDeviceUuid);
    if (activeDevice) {
      setActiveDeviceIfAvailable(activeDevice);
    }
  }, [urlDeviceUuid, devices, activeTransaction]);

  useEffect(() => {
    const setDefaultMethod = async () => {
      await setDefault(paymentMethods[0].id);
    };

    if (paymentMethods.length > 0 && findIndex(paymentMethods, ['defaultFlag', 1]) === -1) {
      setDefaultMethod();
    }
  }, [paymentMethods]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      await refetchGetPaymentMethods();
    };
    fetchPaymentMethods();
  }, [user]);

  return (
    <>
      <div className="pt-1 pb-40">
        <>
          {!user && <TryNowBox />}
          {user && !paymentMethods.length && !isLoading && (
            <div className="mb-6 mt-2 rounded-2xl py-5 px-4 border-2 border-vool-gray-200">
              <div className="text-left text-base font-normal">
                {t('provideBillingDetails', 'Please provide billing details to use our services')}
              </div>
              <button
                className="w-full flex justify-center items-center mt-4 p-2 bg-vool-green text-white rounded-full font-semibold text-base"
                type="button"
                onClick={() =>
                  initStripeSession({
                    returnUrl: `/sites?site=${site.uuid}&device=${
                      activeDeviceUuid ?? 'Select charger'
                    }`,
                  })
                }
              >
                {t('addPaymentMethod', 'Add payment method')}
              </button>
            </div>
          )}
        </>
        <ChargersListing
          title={t('available', 'Available')}
          deviceList={availableDevices}
          activeDeviceUuid={activeDeviceUuid}
          site={site}
          setActiveDeviceIfAvailable={setActiveDeviceIfAvailable}
          siteDeviceMaxPower={siteDeviceMaxPower}
          siteMaxPowerLoading={siteMaxPowerLoading}
        />
        <ChargersListing
          title={t('unavailable', 'Unavailable')}
          deviceList={unavailableDevices}
          activeDeviceUuid={activeDeviceUuid}
          site={site}
          setActiveDeviceIfAvailable={setActiveDeviceIfAvailable}
          siteDeviceMaxPower={siteDeviceMaxPower}
          siteMaxPowerLoading={siteMaxPowerLoading}
        />
      </div>
      {isChargerOpen && activeDevice && (
        <SlideBarChargerView
          device={activeDevice}
          site={site}
          closeSlideBar={() => {
            dispatch(chargerDetailsOpen(false));
            setActiveDeviceUuid(undefined);
            navigate({ search: '' });
          }}
        />
      )}
    </>
  );
};
