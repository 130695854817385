import { useTranslation } from 'react-i18next';

import emptyFaceIcon from 'app/images/icons/emptyFace.svg';

export const NoTransactions = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center flex items-center flex-col pt-8">
      <div className="flex relative items-center justify-center border-4 border-vool-gray-400 rounded-full w-20 h-20">
        <img src={emptyFaceIcon} alt="empty" />
      </div>
      <div className="text-3xl font-TwkEverett p-4">
        {t('nothingToShowYet', 'Nothing to show yet')}
      </div>
      <div className="text-base">
        {t('startChargingToSeeHistory', 'Start charging your vehicle to see history')}
      </div>
    </div>
  );
};
