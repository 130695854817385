import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/solid';

import exitIcon from 'app/images/icons/exit.svg';
import { LanguageSelector } from 'app/layouts/LoggedinBasicLayout/LanguageSelector';
import { trackAmplitudeEvent } from 'services/amplitude';
import { getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { updateFooterNotificationData } from 'services/notifications';

const classNames = (...classes: (string | boolean)[]) => classes.filter(Boolean).join(' ');

type MenuItem = {
  href?: string;
  label?: string;
  amplitudeEvent?: string;
  title: string;
  icon: string;
  onClick?: () => void;
};

type HeaderMenuProps = {
  menuItems?: MenuItem[];
  icon?: string;
  selectedItem?: string;
};

export const HeaderMenu = ({ menuItems, icon, selectedItem }: HeaderMenuProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const handleFooterNotificationVisible = (isVisible: boolean) => {
    dispatch(
      updateFooterNotificationData({
        isVisible,
      }),
    );
  };

  const handleMenuClick = (item: MenuItem) => {
    handleFooterNotificationVisible(true);
    if (item.onClick) {
      item.onClick();
    } else {
      if (item?.amplitudeEvent) {
        trackAmplitudeEvent(item?.amplitudeEvent, getAmplitudeEventPropertiesForUser(user));
      }
      if (item.href) {
        navigate(item?.href);
      }
    }
  };

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button type="button" className="relative z-20 max-w-xs flex items-center">
          {icon ? (
            <img src={icon} alt="menu icon" className="w-12 h-12 p-3" />
          ) : (
            <MenuIcon className="h-5 w-5 text-app-header-link" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        beforeEnter={() => handleFooterNotificationVisible(false)}
        beforeLeave={() => handleFooterNotificationVisible(true)}
      >
        <Menu.Items
          className="origin-top-right fixed z-20 sm:absolute left-0 sm:-left-72 top-0 sm:top-7 sm:right-0 sm:rounded-3xl
        shadow-lg backdrop-filter backdrop-blur-sm sm:backdrop-blur-none bg-vool-black sm:bg-white focus:outline-none h-full
        w-full sm:h-auto sm:w-80 flex flex-col justify-center"
        >
          <Menu.Button
            type="button"
            className="absolute top-10 right-1 w-10 h-10 flex items-center justify-center text-white text-base sm:hidden"
          >
            <img src={exitIcon} alt="exit" className="h-9 w-9 p-2" />
          </Menu.Button>
          <div className="sm:pb-24">
            {menuItems?.map((item) => (
              <Menu.Item key={item.title}>
                {({ active }) => (
                  <div
                    onKeyUp={() => {}}
                    tabIndex={0}
                    role="button"
                    onClick={() => handleMenuClick(item)}
                    className={classNames(
                      (active || selectedItem === item?.href) &&
                        'bg-app-header-link sm:bg-vool-gray-100',
                      item.icon && 'sm:px-6',
                      'flex items-center cursor-pointer text-white sm:text-app-header-link font-normal text-base w-full p-4 sm:py-6 h-16 sm:h-10',
                    )}
                  >
                    {item.icon && (
                      <div
                        className={`h-5 w-5 mr-3 ${
                          selectedItem === item?.href
                            ? 'bg-primary'
                            : 'bg-white sm:bg-app-header-link'
                        }`}
                        style={{
                          WebkitMask: `url(${item.icon}) no-repeat center`,
                          mask: `url(${item.icon}) no-repeat center`,
                        }}
                      />
                    )}
                    <div
                      className={classNames(
                        item.icon && 'pl-1',
                        'block px-4 whitespace-nowrap',
                        selectedItem === item?.href && 'text-primary',
                      )}
                    >
                      {item.title}
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
          <Menu.Button
            as="div"
            className="
            z-20 absolute bottom-4 sm:left-0 sm:right-0 left-4 right-4 flex sm:mx-4 sm:rounded-2xl items-center
            sm:h-16 h-16 rounded-2xl justify-center sm:pt-1 sm:bg-vool-gray-100 bg-vool-black"
          >
            <LanguageSelector />
          </Menu.Button>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
