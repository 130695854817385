import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '@heroicons/react/solid';

import paymentCardIcon from 'app/images/icons/paymentCardIcon.svg';
import apiSlice from 'services/api';

type PaymentItemProps = {
  brand: string;
  number: string;
  expiryDate: string;
  id: string;
  defaultFlag: number;
};

export const PaymentItem = ({ brand, number, expiryDate, defaultFlag, id }: PaymentItemProps) => {
  const { t } = useTranslation();
  const [saveDefaultLoading, setDaveDefaultLoading] = useState(false);
  const [cardDeleteError, setCardDeleteError] = useState(undefined);

  const [setDefault] = apiSlice.useSetPaymentMethodAsDefaultMutation();
  const [deletePaymentMethodEndpoint, { isLoading, error }] =
    apiSlice.useDeletePaymentMethodEndpointMutation();

  const deleteCard = async (cardId: string) => {
    await deletePaymentMethodEndpoint(cardId);
  };

  useEffect(() => {
    if (error) {
      setCardDeleteError((error as any).data.message);
    } else {
      setCardDeleteError(undefined);
    }
  }, [error]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col xs:flex-row items-start xs:items-center justify-between p-4 bg-vool-gray-100 xs:space-x-4 space-y-2 w-full xs:h-24 rounded-2xl">
        <div className="flex items-center justify-center space-x-4">
          <div className="border-[3px] border-primary rounded-full h-14 w-14 flex items-center justify-center">
            <img src={paymentCardIcon} alt="payment icon" />
          </div>
          <div className="flex flex-col items-start justify-center">
            <span className="font-semibold text-base">{brand}</span>
            <span className="font-normal text-sm">{number}</span>
            <span className="font-normal text-sm text-gray-400">
              {t('expiryDate', 'Expiry {{date}}', { date: expiryDate })}
            </span>
          </div>
        </div>
        {defaultFlag === 1 ? (
          <div
            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium text-primary"
            style={{
              background:
                'linear-gradient(0deg, rgba(235, 78, 32, 0.2), rgba(235, 78, 32, 0.2)), #FFFFFF',
            }}
          >
            {t('default', 'Default')}
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center space-y-2">
              <button
                type="button"
                className="border px-2 py-1 rounded-full text-xs font-normal w-full"
                onClick={async () => {
                  setDaveDefaultLoading(true);
                  await setDefault(id);
                  setDaveDefaultLoading(false);
                }}
              >
                {saveDefaultLoading
                  ? t('pleaseWait', 'Please wait ...')
                  : t('setAsDefault', 'Set as default')}
              </button>
              <button
                disabled={isLoading}
                onClick={() => deleteCard(id)}
                type="button"
                className={`
                flex items-center border px-2 py-1 rounded-full relative text-xs font-normal w-full
                ${isLoading ? 'opacity-60' : ''}
              `}
              >
                {t('deleteCard', 'Delete card')}
                <TrashIcon className="w-4 h-4 ml-1" />
              </button>
            </div>
          </>
        )}
      </div>
      {cardDeleteError && (
        <div className="text-red-500 text-sm p-2">
          {t('deleteCardError', 'Error deleting card')}: {cardDeleteError}
        </div>
      )}
    </div>
  );
};
