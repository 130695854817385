import * as Amplitude from '@amplitude/analytics-browser';

import { AMPLITUDE_EVENTS } from './data';

interface IdentifyProps {
  userId?: string;
  email?: string;
}

const AMPLITUDE_CONFIG = JSON.parse(process.env.REACT_APP_AMPLITUDE_CONFIG ?? '')[
  process.env.REACT_APP_CONF_FILE ?? ''
];

const setAmplitudeIdentify = ({ userId, email }: IdentifyProps): void => {
  const identify = new Amplitude.Identify();
  if (!userId || !email) {
    identify.clearAll();
  } else {
    identify.set('email', email);
    identify.set('userId', userId);
  }
  Amplitude.setUserId(userId);
  const existingDeviceId = localStorage.getItem('amplitude_device_id');
  if (existingDeviceId) {
    Amplitude.setDeviceId(existingDeviceId);
  }
  Amplitude.identify(identify);
  if (!existingDeviceId) {
    localStorage.setItem('amplitude_device_id', Amplitude.getDeviceId() ?? '');
  }
};

interface CustomTrackEventHandlers {
  [key: string]: (eventName: string, eventProperties: IdentifyProps) => Promise<void>;
}

const customTrackEventHandlers: CustomTrackEventHandlers = {
  [AMPLITUDE_EVENTS.LOGIN]: async (eventName, eventProperties) => {
    const { userId, email } = eventProperties;
    if (userId !== Amplitude.getUserId()) {
      setAmplitudeIdentify({ userId, email });
      await Amplitude.track(eventName, eventProperties).promise;
    }
  },
  [AMPLITUDE_EVENTS.LOGOUT]: async (eventName, eventProperties) => {
    await Amplitude.track(eventName, eventProperties).promise;
    setAmplitudeIdentify({ userId: undefined, email: undefined });
    Amplitude.reset();
  },
};

export const initAmplitude = (): void => {
  if (AMPLITUDE_CONFIG) {
    Amplitude.init(AMPLITUDE_CONFIG.key);
  }
};

export const trackAmplitudeEvent = async (
  eventName: string,
  eventProperties: Record<string, unknown>,
): Promise<void> => {
  if (!AMPLITUDE_CONFIG) {
    return;
  }
  const customHandler = customTrackEventHandlers[eventName];
  if (customHandler) {
    await customHandler(eventName, eventProperties);
  } else {
    await Amplitude.track(eventName, eventProperties).promise;
  }
};
