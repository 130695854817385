export enum OcppStatus {
  OFFLINE = 'Offline',
  PLUGGED_IN = 'Plugged in',
  CHARGING = 'Charging',
  UNAVAILABLE = 'Not available',
  AVAILABLE = 'Available',
  OCCUPIED = 'Occupied',
  PENDING = 'Pending',
  RESERVED = 'Reserved',
}
