import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoginBox } from 'app/views/Login/LoginBox';
import { ResetPasswordBox } from 'app/views/Login/ResetPasswordBox';
import { RegisterBox } from 'app/views/Register/RegisterBox';
import { GoogleAuth } from 'components/google/GoogleAuth';
import { LOGIN_FORM_OPTIONS } from 'handlers/login-form-options.enum';

type LoginProps = {
  formOption?: string;
  modal?: boolean;
  closeModal: () => void;
  onClose?: () => void;
};

export const Login = ({
  formOption = LOGIN_FORM_OPTIONS.LOGIN,
  modal,
  closeModal,
  onClose,
}: LoginProps) => {
  const [form, setForm] = useState(formOption);
  const { t } = useTranslation();

  useEffect(() => {
    setForm(formOption);
  }, [formOption]);
  useEffect(() => onClose, []);

  // TODO: Refactor to use a type instead of any
  const FormComponent = (props: any) =>
    ({
      [LOGIN_FORM_OPTIONS.LOGIN]: <LoginBox {...props} />,
      [LOGIN_FORM_OPTIONS.REGISTER]: <RegisterBox {...props} />,
      [LOGIN_FORM_OPTIONS.RESET_PASSWORD]: <ResetPasswordBox {...props} />,
    }[form]);

  return (
    <div
      className={`flex flex-col w-full h-full ${
        form === LOGIN_FORM_OPTIONS.RESET_PASSWORD ? 'justify-center' : ''
      }`}
    >
      <FormComponent
        modal={modal}
        closeModal={closeModal}
        onEvent={(newValue: string) => {
          setForm(newValue);
        }}
      />
      {form === LOGIN_FORM_OPTIONS.REGISTER && (
        <>
          <div className="text-sm py-2 sm:py-2px text-vool-gray-400 font-normal flex justify-center items-center">
            {t('or', 'or')}
          </div>
          <GoogleAuth closeModal={closeModal} />
        </>
      )}
    </div>
  );
};
