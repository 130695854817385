import { ApiEndpointBuilder } from 'services/api/endpoints';

import {
  AddPaymentMethodResponse,
  DeletePaymentMethodResponse,
  InvoicesResponse,
  PaymentMethodsResponse,
  SetDefaultPaymentResponse,
} from './responses';

export const buildBillingEndpoints = (builder: ApiEndpointBuilder) => ({
  addPaymentMethod: builder.mutation<AddPaymentMethodResponse, { returnUrl: string }>({
    query: ({ returnUrl }) => ({
      url: '/billing/paymentMethods',
      method: 'POST',
      body: { returnUrl },
    }),
  }),
  addPublicPaymentMethod: builder.mutation<AddPaymentMethodResponse, { returnUrl: string }>({
    query: ({ returnUrl }) => ({
      url: '/billing/publicPaymentMethod',
      method: 'POST',
      body: { returnUrl },
    }),
  }),
  deletePaymentMethodEndpoint: builder.mutation<DeletePaymentMethodResponse, string>({
    query: (paymentMethodId) => ({
      url: '/billing/paymentMethods',
      method: 'DELETE',
      body: { paymentMethodId },
    }),
  }),
  setPaymentMethodAsDefault: builder.mutation<SetDefaultPaymentResponse, string>({
    query: (id) => ({
      url: '/billing/paymentMethods/default',
      method: 'POST',
      body: { default: id },
    }),
  }),
  getPaymentMethods: builder.query<PaymentMethodsResponse, void>({
    query: () => ({
      url: '/billing/paymentMethods',
      method: 'GET',
    }),
  }),
  getInvoices: builder.query<InvoicesResponse, void>({
    query: () => ({
      url: '/billing/invoices',
      method: 'GET',
    }),
  }),
});
