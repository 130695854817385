import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import { Input } from 'components/atoms/Input';
import { PasswordInput } from 'components/atoms/PasswordInput';
import { Grid } from 'components/grid/Grid';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { useForm } from 'hooks/user-form';
import { get } from 'lodash';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required*'),
    currentPassword: yup.string(),
    newPassword: yup
      .string()
      .test('required', 'Required*', (value, context) =>
        context.parent.currentPassword ? !!value : true,
      ),
    repeatPassword: yup
      .string()
      .test('required', 'Required*', (value, context) =>
        context.parent.currentPassword ? !!value : true,
      )
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  })
  .required();

export const AccountView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formState, register, setValue, handleSubmit } = useForm({ schema });

  const [passwordError, setPasswordError] = useState<string>();
  const [accountUpdateSuccess, setAccountUpdateSuccess] = useState(false);
  const user = useSelector(selectUser);

  const [updateSelf, { isLoading: updateSelfLoading }] = apiSlice.useUpdateSelfMutation();
  const [updatePassword, { isLoading: updatePasswordLoading }] =
    apiSlice.useUpdateUserPasswordMutation();

  const onSubmit = async ({ name, currentPassword, newPassword }: any) => {
    let updateError;
    setAccountUpdateSuccess(false);
    setPasswordError(undefined);

    try {
      if (name !== user?.name) {
        await updateSelf({ name });
      }
      if (currentPassword && newPassword) {
        const response = await updatePassword({
          currentPassword,
          newPassword,
        });
        if ('error' in response) {
          updateError = response.error;
        }

        if (updateError) {
          const errorMessage = get(updateError, 'data.message', 'Something went wrong');
          setPasswordError(errorMessage);
        } else {
          setValue('currentPassword', '');
          setValue('newPassword', '');
          setValue('repeatPassword', '');

          trackAmplitudeEvent(
            AMPLITUDE_EVENTS.SAVE_ACCOUNT_DETAILS,
            getAmplitudeEventPropertiesForUser(user),
          );
        }
      }

      setAccountUpdateSuccess(!updateError);
      // TODO: Need to check what error is caught here
    } catch (error) {
      setPasswordError(error as string);
    }
  };

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
      setValue('email', user.email);
    }
  }, [user]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex flex-col">
        <div className="w-full flex items-center justify-start">
          <button type="button" className="h-10 w-10" onClick={() => navigate('/sites')}>
            <img src={arrowRightIcon} style={{ transform: 'scaleX(-1)' }} alt="Go back" />
          </button>
          <h3 className="text-2xl leading-6 font-normal text-app-header-link">
            {t('account', 'Account')}
          </h3>
        </div>
        {accountUpdateSuccess && (
          <div className="text-md text-vool-green mb-2">{t('accountUpdateSuccess', 'Updated')}</div>
        )}
        <div className="font-medium text-lg mt-3 mb-2">{t('details', 'Details')}</div>
        <Grid maxCol={2}>
          <div className="space-y-4">
            <Input
              label={t('name', 'Name')}
              error={Boolean(formState.errors?.name)}
              helpText={formState.errors?.name?.message as string}
              className="rounded-8px"
              {...register('name')}
            />
            <Input
              label={t('email', 'Email')}
              error={Boolean(formState.errors?.email)}
              helpText={formState.errors?.email?.message as string}
              className="rounded-8px"
              inputProps={{ disabled: true }}
              {...register('email')}
            />
          </div>
        </Grid>
        {user?.hasPassword ? (
          <>
            <div className="font-medium text-lg mt-8 mb-2">
              {t('changePassword', 'Change password')}
            </div>
            {passwordError && <div className="text-sm text-red-500 mb-4">{passwordError}</div>}
            <Grid>
              <div className="space-y-4">
                <PasswordInput
                  label={t('currentPassword', 'Current password')}
                  error={Boolean(formState.errors?.currentPassword)}
                  helpText={formState.errors?.currentPassword?.message as string}
                  className="rounded-8px"
                  autoComplete="new-password"
                  {...register('currentPassword')}
                />
                <PasswordInput
                  label={t('newPassword', 'New password')}
                  error={Boolean(formState.errors?.newPassword)}
                  helpText={formState.errors?.newPassword?.message as string}
                  className="rounded-8px"
                  autoComplete="new-password"
                  {...register('newPassword')}
                />
                <PasswordInput
                  label={t('repeatPassword', 'Repeat new password')}
                  error={Boolean(formState.errors?.repeatPassword)}
                  helpText={formState.errors?.repeatPassword?.message as string}
                  className="rounded-8px"
                  autoComplete="new-password"
                  {...register('repeatPassword')}
                />
              </div>
            </Grid>
            <Grid maxCol={2} className="mt-20">
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-vool-green rounded-full text-white p-4 max-w-xs flex-grow relative"
                  disabled={!formState.isDirty}
                >
                  {(updateSelfLoading || updatePasswordLoading) && <OverflowSpinner size={6} />}
                  {t('save', 'Save')}
                </button>
              </div>
            </Grid>
          </>
        ) : null}
      </div>
    </form>
  );
};
