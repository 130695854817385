import { Transaction } from 'models/transaction';
import { ApiEndpointBuilder } from 'services/api/endpoints';

export interface TransactionsResponse {
  success: boolean;
  transactions: Transaction[];
}

export interface TransactionResponse {
  success: boolean;
  transaction: Transaction;
}

export interface StartCharge {
  deviceUuid: string;
  connectorId: number;
  carUuid?: string;
}

export interface StopCharge {
  deviceSerialNumber: string;
  connectorId: number;
}

export const buildTransactionsEndpoints = (builder: ApiEndpointBuilder) => ({
  customerTransactions: builder.query<TransactionsResponse, void>({
    query: () => ({
      url: 'transactions/customer',
      method: 'GET',
    }),
  }),
  lastTransactions: builder.query<TransactionsResponse, void>({
    query: () => ({
      // url: 'transactions/last',
      url: 'transactions',
      method: 'GET',
    }),
  }),
  getTransaction: builder.query<TransactionResponse, string>({
    query: (uuid) => ({
      url: `/transactions/${uuid}?userContext=transaction`,
      method: 'GET',
    }),
  }),
  startCharging: builder.mutation<TransactionResponse, StartCharge>({
    query: (body) => ({
      url: '/ocpp/start',
      method: 'POST',
      body,
    }),
  }),
  // TODO: Figure out what type is actually returned
  stopCharging: builder.mutation<any, StopCharge>({
    query: (body) => ({
      url: '/ocpp/stop',
      method: 'POST',
      body,
    }),
  }),
  activeTransaction: builder.query<TransactionResponse, undefined>({
    query: () => ({
      url: '/transactions/activeTransaction',
      method: 'GET',
    }),
  }),
  getTransactionGraphData: builder.query({
    query: ({ transactionUuid }) => ({
      url: `/graphs/transaction/${transactionUuid}`,
      method: 'GET',
    }),
  }),
  cancelPendingTransaction: builder.mutation({
    query: () => ({
      url: '/transactions/pendingTransaction',
      method: 'DELETE',
    }),
  }),
});
