import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { PasswordInput } from 'components/atoms/PasswordInput';
import { GoogleAuth } from 'components/google/GoogleAuth';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { LOGIN_FORM_OPTIONS } from 'handlers/login-form-options.enum';
import { useForm } from 'hooks/user-form';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import apiSlice from 'services/api';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Invalid email')
      .required('Required*')
      .matches(/^\S+@\S+$/, { message: 'Please provide a valid email address' }),
    password: yup.string().required('Required*'),
  })
  .required();

type LoginBoxProps = {
  modal?: boolean;
  closeModal: () => void;
  onEvent: (event: string) => void;
};

export const LoginBox = ({ modal, closeModal, onEvent }: LoginBoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { register, handleSubmit, formState, formError, setFormError } = useForm({ schema });
  const [login, { isLoading, isError, error: apiError }] = apiSlice.useLoginMutation();

  const onSubmit = async ({ email, password }: any) => {
    try {
      const response = await login({ email, password });
      if (!('error' in response)) {
        if (modal) {
          closeModal();
          onEvent(LOGIN_FORM_OPTIONS.LOGIN);
        } else {
          navigate('/');
        }

        trackAmplitudeEvent(AMPLITUDE_EVENTS.LOGIN, {
          ...getAmplitudeEventPropertiesForUser(response?.data?.user),
          method: 'Email',
        });
      }
    } catch (error) {
      // TODO: What is going on here?
      // eslint-disable-next-line no-console
      console.log('ERROR: ', error);
    }
  };

  useEffect(() => {
    // TODO: Fix error handling
    if (apiError) {
      const fetchError = apiError as any;
      if (fetchError?.data?.message) {
        setFormError(fetchError?.data?.message || 'Something went wrong');
      } else {
        setFormError(`Something went wrong: ${fetchError?.error}`);
      }
    }
  }, [isError]);

  return (
    <div className="bg-white flex flex-col w-full">
      <span className="font-TwkEverett sm:pt-1 pt-16 flex flex-col justify-center text-32px">
        {t('loginTitle', 'Welcome Back')} 👋
      </span>
      <span className="sm:pt-2 pt-5 flex flex-col justify-center">
        {t('loginMessage', 'You know what to do')}
      </span>
      <form
        style={{ position: 'relative' }}
        className="space-y-2 pt-8 sm:pt-10 text-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        {formError && <div className="text-red-500 text-sm">{formError}</div>}
        <div className="space-y-4">
          <Input
            label={t('email', 'Email address')}
            error={Boolean(formState.errors?.email)}
            helpText={formState.errors?.email?.message as string}
            className="rounded-8px"
            {...register('email')}
          />
          <PasswordInput
            label={t('password', 'Password')}
            error={Boolean(formState.errors?.password)}
            helpText={formState.errors?.password?.message as string}
            className="rounded-8px"
            {...register('password')}
          />
        </div>
        <button
          type="button"
          className="text-sm p-2 font-normal text-app-general-dark hover:opacity-80 underline"
          style={{ textUnderlineOffset: '0.5em' }}
          onClick={() => onEvent(LOGIN_FORM_OPTIONS.RESET_PASSWORD)}
        >
          {t('forgotPassword', 'Forgot your password?')}
        </button>
        <div className="sm:pt-2 pt-3">
          <Button
            type="submit"
            radiusClass="rounded-full"
            className="w-full h-14 text-base font-semibold"
            color="bg-vool-green"
            fontSize="text-base"
            fontWeight="font-semibold"
            shadow="shadow-none"
          >
            {t('signIn', 'Sign in')}
          </Button>
        </div>
        <div className="text-sm sm:py-0.5 py-4 text-vool-gray-400 font-normal flex justify-center items-center">
          {t('or', 'or')}
        </div>
        <GoogleAuth closeModal={closeModal} />
        <button
          type="button"
          className="font-medium pt-7 text-base text-app-general-dark hover:opacity-80 underline"
          style={{ textUnderlineOffset: '0.5em' }}
          onClick={() => onEvent(LOGIN_FORM_OPTIONS.REGISTER)}
        >
          {t('newHere?SignUp', 'New here? SIGN UP!')}
        </button>
        {isLoading && <OverflowSpinner size={16} />}
      </form>
    </div>
  );
};
