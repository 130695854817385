import { ParkingAndReservation } from 'models/parking-and-reservation';
import { ApiEndpointBuilder } from 'services/api/endpoints';

interface ParkingAndReservationResponse {
  success: boolean;
  data: ParkingAndReservation;
}

export const buildParkingAndReservationEndpoints = (builder: ApiEndpointBuilder) => ({
  reserveDevice: builder.mutation<ParkingAndReservationResponse, string>({
    query: (deviceUuid) => ({
      url: `/devices/${deviceUuid}/reservation`,
      method: 'POST',
    }),
  }),
  cancelDeviceReservation: builder.mutation<{ success: boolean }, string>({
    query: (deviceUuid) => ({
      url: `/devices/${deviceUuid}/reservation`,
      method: 'DELETE',
    }),
  }),
  cancelParking: builder.mutation<{ success: boolean }, void>({
    query: () => ({
      url: '/sites/parking',
      method: 'DELETE',
    }),
  }),
  getActiveParkingAndReservation: builder.query<ParkingAndReservationResponse, void>({
    query: () => ({
      url: '/devices/activeParkingAndReservation',
      method: 'GET',
    }),
  }),
});
