import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';

import { AuthGuard } from './core/AuthGuard';
import { Layout } from './layouts/LoggedinBasicLayout/Layout';
import { customerPortalPrivateRoutes, customerPortalPublicRoutes } from './routesConfig';

const renderRoutes = (publicR: RouteProps[], privateR: RouteProps[]) => (
  <Routes>
    <Route element={<Layout />}>
      {publicR.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route element={<AuthGuard />}>
        {privateR.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>
    </Route>
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export const Router = () => {
  return renderRoutes(customerPortalPublicRoutes, customerPortalPrivateRoutes);
};
