import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getApiUrl } from 'config/api';
import { getCookie, setCookie } from 'handlers/cookie.handler';
import { removeCredentials } from 'services/auth';

import { buildEndpoints } from './endpoints';

const baseQuery = fetchBaseQuery({
  baseUrl: getApiUrl(),
  prepareHeaders: (headers) => {
    const tokenCookie = getCookie('cu_token') ?? getCookie('cu_temporaryToken');
    headers.set('X-User-Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    if (tokenCookie) {
      headers.set('Authorization', `Bearer ${tokenCookie}`);
    }

    return headers;
  },
});

type RefreshTokenResponse = {
  token: string;
  refreshToken: string;
};

type ExpiryTime = {
  temporaryToken: number;
};

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const oldRefreshToken = getCookie('cu_refreshToken');

    if (oldRefreshToken) {
      const refreshResult = await baseQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
          body: { refreshToken: oldRefreshToken },
        },
        api,
        extraOptions,
      );

      const { token, refreshToken: newRefreshToken } =
        (refreshResult.data as RefreshTokenResponse) || {};

      setCookie('cu_token', token, { expires: 30, sameSite: 'Strict' });
      setCookie('cu_refreshToken', newRefreshToken, { expires: 30, sameSite: 'Strict' });
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(removeCredentials());
    }
  } else if (result?.data) {
    const expiryTime: ExpiryTime = {
      temporaryToken: 6 / 24,
    };

    Object.entries(result?.data ?? {})
      .filter(([key]) => key.toLowerCase().includes('token'))
      .forEach(([tokenName, tokenValue]) => {
        setCookie(`cu_${tokenName}`, tokenValue, {
          expires: expiryTime[tokenName as keyof ExpiryTime] || 30,
          sameSite: 'Strict',
        });
      });
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: 'api',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => buildEndpoints(builder),
});

export default apiSlice;
