import chargerAvailableIcon from 'app/images/icons/chargerAvailable.svg';
import chargerChargingIcon from 'app/images/icons/chargerCharging.svg';
import chargerNotAvailableIcon from 'app/images/icons/chargerNotAvailable.svg';
import { every } from 'lodash';
import { Device, Evse } from 'models/device';
import { DeviceStatus, DeviceTransactionStatus } from 'models/device.enums';
import { ParkingAndReservation } from 'models/parking-and-reservation';
import { Transaction } from 'models/transaction';

import { OcppStatus } from './ocpp-status.enum';

export const getEvseStatus = (
  evse: Evse,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  const { status, transactionStatus, ocppStatus } = evse;

  if (status === DeviceStatus.OFFLINE || !status) {
    return OcppStatus.OFFLINE;
  }

  if (ocppStatus === OcppStatus.OCCUPIED && transactionStatus === DeviceTransactionStatus.NONE) {
    return OcppStatus.PLUGGED_IN;
  }

  if (activeReservation?.deviceUuid === evse.uuid) {
    return OcppStatus.AVAILABLE;
  }

  if (ocppStatus === OcppStatus.RESERVED) {
    return OcppStatus.OCCUPIED;
  }

  if (
    ocppStatus === OcppStatus.OCCUPIED &&
    transactionStatus === DeviceTransactionStatus.IN_PROGRESS &&
    getEvseActiveCharge(evse, activeTransaction)
  ) {
    return OcppStatus.CHARGING;
  }

  if (
    every([
      ocppStatus === OcppStatus.OCCUPIED,
      transactionStatus === DeviceTransactionStatus.PENDING,
      getEvseActiveCharge(evse, activeTransaction),
    ])
  ) {
    return OcppStatus.PENDING;
  }

  if (ocppStatus && Object.values(OcppStatus).includes(ocppStatus)) {
    return ocppStatus;
  }

  return OcppStatus.UNAVAILABLE;
};

export const getEvseActiveCharge = (evse: Evse, activeTransaction?: Transaction) => {
  return activeTransaction?.deviceUuid === evse.uuid;
};

export const isEvseAvailable = (
  evse: Evse,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  const status = getEvseStatus(evse, activeTransaction, activeReservation);

  return [
    OcppStatus.AVAILABLE,
    OcppStatus.CHARGING,
    OcppStatus.PLUGGED_IN,
    OcppStatus.PENDING,
  ].includes(status);
};

export const getEvseStatusImage = (
  evse: Evse,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  if (!isEvseAvailable(evse, activeTransaction, activeReservation)) {
    return chargerNotAvailableIcon;
  }

  if (getEvseStatus(evse, activeTransaction) === OcppStatus.CHARGING) {
    return chargerChargingIcon;
  }

  return chargerAvailableIcon;
};

export const createEvses = (device: Device) => {
  const evses: Evse[] = [];
  if (device.configOfConnectors) {
    for (let index = 0; index < device.configOfConnectors.length; index++) {
      const config = device?.configOfConnectors[index];
      const status = device?.stateOfConnectors?.[index];
      evses.push({
        activePower: device.activePower,
        uuid: device.uuid,
        userUuid: device.userUuid,
        companyUuid: device.companyUuid,
        deviceUuid: device.uuid,
        evseId: `${device.uuid}-${config.connectorId}`,
        siteUuid: device.siteUuid,
        name: device.name,
        status: device.status,
        ocppVersion: device.ocppVersion,
        vendor: device.vendor,
        model: device.model,
        firmwareVersion: device.firmwareVersion,
        type: device.type,
        serialNumber: device.serialNumber,
        deviceSerialNumber: device.deviceSerialNumber,
        pinCode: device.pinCode,
        deleted: device.deleted,
        addTime: device.addTime,
        updateTime: device.updateTime,
        gridConnection: device.gridConnection,
        ocppPassword: device.ocppPassword,
        connectorId: config.connectorId,
        powerType: config.powerType,
        connectorType: config.connectorType,
        connectorFormat: config.connectorFormat,
        maxPowerKw: config.maxPowerKw,
        ocppStatus: status?.ocppStatus,
        transactionStatus: status?.transactionStatus,
      });
    }
  }
  return evses;
};

export const isAnyEvseAvailable = (
  device: Device,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  const evses = createEvses(device);
  return evses.some((evse) => isEvseAvailable(evse, activeTransaction, activeReservation));
};
