import { useTranslation } from 'react-i18next';

import chargingIcon from 'app/images/icons/charging.svg';
import profitIcon from 'app/images/icons/profit.svg';
import tagIcon from 'app/images/icons/tag.svg';
import { Card } from 'components/atoms/Card';
import { DarkTooltip } from 'components/atoms/DarkTooltip';
import { SiteHandler } from 'handlers/site.handler';
import { Transaction } from 'models/transaction';
import apiSlice from 'services/api';

type ChargerDetailsCardProps = {
  activeTransaction?: Transaction;
  siteHandler: SiteHandler;
  maxPower: number;
};

export const ChargerDetailsCard = ({ siteHandler, maxPower }: ChargerDetailsCardProps) => {
  const { t } = useTranslation();
  const fixedPublicPrice = siteHandler.getPublicFixedPrice();

  const { isLoading: siteDeviceMaxPowerLoading } = apiSlice.useGetSiteDeviceMaxPowerQuery(
    { uuid: siteHandler.getData().uuid },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const chargeData = [
    {
      title: t('pricing', 'Pricing'),
      icon: tagIcon,
      text: siteHandler.isMarketPrice() ? t('market', 'Market') : t('fixed', 'Fixed'),
      tooltip: t('marketPriceTooltip', 'Market based including seller margin'),
      disabled: !siteHandler.isMarketPrice(),
    },
    {
      title: siteHandler.isMarketPrice() ? t('upcharge', 'Upcharge') : t('price', 'Price'),
      icon: profitIcon,
      text: `${fixedPublicPrice} €/kWh`,
      disabled: siteHandler.isMarketPrice(),
    },
    {
      title: t('power', 'Power'),
      icon: chargingIcon,
      text: `${maxPower}kW`,
      loading: siteDeviceMaxPowerLoading,
    },
  ];

  return (
    <Card
      wrapperClass="p-2 px-4 sm:px-6 text-center pb-4"
      className="p-2 bg-vool-gray-100"
      full
      radiusClass="rounded-2xl"
      borderless
    >
      <div className="flex w-full text-left items-center">
        {chargeData.map(
          (item) =>
            !item.disabled && (
              <div
                key={item.title}
                className="flex items-center justify-start w-full xs:w-1/2 p-2 space-x-4"
              >
                <img src={item.icon} alt={item.title} />
                <div className="flex flex-col items-start justify-center w-full">
                  <span className="text-xs text-gray-500 font-normal">{item.title}</span>
                  <span className="flex items-center text-base text-black font-semibold">
                    {item.loading ? '-' : item.text}
                    <span className="absolute left-28">
                      {item.tooltip && <DarkTooltip title={item.tooltip} />}
                    </span>
                  </span>
                </div>
              </div>
            ),
        )}
      </div>
    </Card>
  );
};
