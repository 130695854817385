import { Box } from '@mui/material';

import { Site } from 'models/site';

import { SinglePublicSite } from './SinglePublicSite';

type SiteListProps = {
  sites: Site[];
};

export const SiteList = ({ sites }: SiteListProps) => {
  return (
    <Box
      className="no-scrollbar pb-24"
      sx={{
        px: 2,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {sites.map((site) => (
        <SinglePublicSite site={site} key={site.uuid} />
      ))}
    </Box>
  );
};
