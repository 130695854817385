import { createSelector } from 'reselect';
import { RootState } from 'services/store';

export const billingSlice = (state: RootState) => state.billing;

export const selectStripeSessionUrl = createSelector(
  billingSlice,
  (slice) => slice.stripeSessionUrl,
);
export const selectPaymentMethods = createSelector(billingSlice, (slice) => slice.paymentMethods);
export const selectInvoices = createSelector(billingSlice, (slice) => slice.invoices);
export const selectStripeSessionUser = createSelector(
  billingSlice,
  (slice) => slice.publicStripeSessionUser,
);
