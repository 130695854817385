import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import apiSlice from 'services/api';
import { selectStripeSessionUrl } from 'services/billing/selectors';
import { updateActiveSiteAndCharger } from 'services/sites/actions';
import { selectActiveSiteAndCharger } from 'services/sites/selectors';

import { CompanyInfo } from './CompanyInfo';
import { Invoices } from './Invoices';
import { PaymentMethods } from './PaymentMethods';

export const Billing = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const urlSiteUuid = searchParams.get('site');
  const urlDeviceUuid = searchParams.get('device');
  const activeSiteAndCharger = useSelector(selectActiveSiteAndCharger);
  const [initStripeSession] = apiSlice.useAddPaymentMethodMutation();
  const stripeSessionUrl = useSelector(selectStripeSessionUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (stripeSessionUrl) {
      window.location.href = stripeSessionUrl;
    }
  }, [stripeSessionUrl]);

  useEffect(() => {
    if (urlSiteUuid && urlDeviceUuid) {
      dispatch(updateActiveSiteAndCharger({ site: urlSiteUuid, device: urlDeviceUuid }));
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-start justify-center">
        {/* TODO: Might not be used anymore */}
        {activeSiteAndCharger && (
          <button
            type="button"
            className="p-4 mb-2 bg-green-50 w-full text-left text-green-700"
            onClick={() =>
              navigate(
                `/sites?site=${activeSiteAndCharger.site}&device=${activeSiteAndCharger.device}`,
              )
            }
          >
            {'< Go back to charger view'}
          </button>
        )}
        <div className="flex items-center justify-start pb-6 space-x-2">
          <button type="button" onClick={() => navigate('/sites')}>
            <img src={arrowRightIcon} style={{ transform: 'scaleX(-1)' }} alt="Go back" />
          </button>
          <h3 className="text-2xl leading-6 font-normal text-app-header-link">
            {t('billingDetails', 'Billing details')}
          </h3>
        </div>
        <div className="flex flex-col items-start justify-center w-full space-y-1">
          <span className="text-lg font-semibold">{t('savedCards', 'Saved cards')}</span>
          <PaymentMethods />
          {loading ? (
            <div className="text-sm">{t('pleaseWait', 'Please wait ...')}</div>
          ) : (
            <button
              type="button"
              className="flex items-center text-primary underline font-medium text-base"
              style={{ textUnderlineOffset: '4px' }}
              onClick={() => {
                setLoading(true);
                initStripeSession({
                  returnUrl: `/billing${
                    activeSiteAndCharger
                      ? `?site=${activeSiteAndCharger.site}&device=${activeSiteAndCharger.device}`
                      : ''
                  }`,
                });
              }}
            >
              {t('addNewPaymentMethod', 'Add new payment method')}
            </button>
          )}
        </div>
        <div className="py-7 w-full">
          <CompanyInfo />
        </div>
      </div>

      <div className="py-3 pb-4">
        <h3 className="text-lg leading-6 font-semibold text-app-header-link">
          {t('billingHistory', 'Billing history')}
        </h3>
      </div>
      <Invoices />
    </>
  );
};
