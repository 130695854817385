import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Global } from '@emotion/react';
import { Dialog, Transition } from '@headlessui/react';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon, XIcon } from '@heroicons/react/solid';
import { mdiNavigationVariantOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { Site } from 'models/site';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForSite } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { selectFooterNotification } from 'services/notifications/selectors';
import { updateActiveSiteAndCharger } from 'services/sites/actions';

import { SlideBarChargers } from './SlideBarChargers';
import { SlideBarEmptyView } from './SlideBarEmptyView';

type SlideBarProps = {
  isOpen: boolean;
  closeSlidebar: () => void;
  site: Site;
  setDestination: (destination: any) => void;
};

export const SlideBar = ({ isOpen, closeSlidebar, site, setDestination }: SlideBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const footerNotification = useSelector(selectFooterNotification);
  const parsedAddressJson = site.addressJson;

  const handleSelectSiteAsDestination = () => {
    trackAmplitudeEvent(
      AMPLITUDE_EVENTS.NAVIGATE_TO_SITE,
      getAmplitudeEventPropertiesForSite({ site, user }),
    );
    setDestination(parsedAddressJson);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="w-0 fixed inset-0 overflow-hidden z-20" onClose={() => {}}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen h-screen sm:max-w-md bg-white shadow-xl overflow-y-scroll no-scrollbar">
                <div className="sticky z-20 top-0 flex items-center sm:items-start justify-start sm:flex-row-reverse sm:justify-between pl-14 sm:pl-6 pr-4 sm:pr-6 py-4 bg-white">
                  <button
                    type="button"
                    className="absolute top-6 left-4 sm:static rounded-full bg-white sm:bg-vool-gray-100 p-2 text-black"
                    onClick={() => {
                      dispatch(updateActiveSiteAndCharger(undefined));
                      closeSlidebar();
                    }}
                  >
                    <XIcon className="hidden sm:block h-5 w-5" aria-hidden="true" />
                    <ArrowLeftIcon className="sm:hidden h-5 w-5" aria-hidden="true" />
                  </button>
                  <Dialog.Title className="w-full h-full">
                    <span className="font-normal text-2xl overflow-wrap-anywhere">{site.name}</span>
                    <div className="flex flex-col xs:flex-row items-start justify-between text-sm text-vool-gray-400  w-full">
                      <span>{site.address}</span>
                      {site.distance && (
                        <span className="flex items-center whitespace-nowrap justify-start xs:justify-end space-x-1 w-20">
                          <LocationMarkerIcon className="h-4 w-4 mr-1" />
                          {site.distance.text}
                        </span>
                      )}
                    </div>
                  </Dialog.Title>
                </div>
                <div className="flex-1 px-4 pb-6 sm:px-6">
                  {site.devices.length ? (
                    // TODO: Fix siteMaxPowerLoading, can't see from where it should come from
                    <SlideBarChargers
                      devices={site.devices}
                      site={site}
                      siteMaxPowerLoading={false}
                    />
                  ) : (
                    <SlideBarEmptyView />
                  )}
                </div>
                {!!user && (
                  <>
                    <Global
                      styles={{
                        '.vool-navigation-button': {
                          bottom: '24px',
                          '@media (max-width: 568px)': {
                            bottom: `${12 + footerNotification.height}px`,
                          },
                        },
                      }}
                    />
                    <button
                      onClick={handleSelectSiteAsDestination}
                      type="button"
                      className="vool-navigation-button absolute z-20 right-0 left-0 w-36 ml-auto mr-auto bg-vool-green p-1 py-3 rounded-full"
                    >
                      <a
                        href={`https://www.google.com/maps/dir/My+Location/${parsedAddressJson?.lat},${parsedAddressJson?.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white text-base font-semibold w-full flex justify-center items-center space-x-2"
                      >
                        <Icon
                          path={mdiNavigationVariantOutline}
                          size="18px"
                          style={{ transform: 'scaleX(-1)' }}
                        />
                        <span>{t('navigate', 'Navigate')}</span>
                      </a>
                    </button>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
