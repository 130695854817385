import { Transaction } from 'models/transaction';
import { createSelector } from 'reselect';
import { RootState } from 'services/store';

export const selectSlice = (state: RootState) => state.transactions;

export const selectTransactions = createSelector(selectSlice, (slice) => slice.transactions);

export const selectTransaction = (transactionId: string) =>
  createSelector(selectTransactions, (transactions) =>
    transactions?.list?.find(
      (transaction: Transaction) => transaction.transactionId === transactionId,
    ),
  );

export const selectLastTransactions = createSelector(
  selectSlice,
  (slice) => slice.transactions.lastTransactions,
);

export const selectActiveTransaction = createSelector(
  selectSlice,
  (slice) => slice.activeTransaction,
);
