import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { Input } from 'components/atoms/Input';
import { SingleCardWrapper } from 'components/atoms/SingleCardWrapper';
import { CardSpinner } from 'components/spinner/CardSpinner';
import { useForm } from 'hooks/user-form';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';
import * as yup from 'yup';

import { AuthModal } from '../Login/AuthModal';
import { ResetPasswordBox } from '../Login/ResetPasswordBox';

const schema = yup
  .object()
  .shape({
    password: yup.string().required('Required*'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();

  const user = useSelector(selectUser);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [authOpen, setAuthOpen] = useState(false);

  const { register, handleSubmit, formState } = useForm({ schema });

  const { isError, error: apiError, isLoading } = apiSlice.useCheckPasswordHashQuery({ token });

  const [
    changeResetPassword,
    {
      isLoading: passwordChangeInProgress,
      isSuccess: passwordChangeSuccess,
      isError: isPasswordChangeError,
    },
  ] = apiSlice.useChangeResetPasswordMutation();

  const onSubmit = async ({ password }: any) => {
    await changeResetPassword({ password, token });
  };

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }

    if (passwordChangeInProgress || isLoading) {
      setLoading(passwordChangeInProgress || isLoading);
    }

    if (isError || isPasswordChangeError) {
      const apisError = isError || isPasswordChangeError;
      const jwtExpired = t(
        'passwordResetLinkIsExpired',
        'Password reset link is expired. Send a new one.',
      );
      setError(apisError ? jwtExpired : t('somethingWentWrong', 'Something went wrong'));
    }
  }, [isLoading, apiError, passwordChangeInProgress]);

  useEffect(() => {
    if (user?.uuid && passwordChangeSuccess) {
      navigate('/sites');
    }
  }, [user]);

  if (error) {
    return (
      <div className="absolute flex flex-col items-center justify-center h-full w-full left-0 top-0">
        <ResetPasswordBox onEvent={() => navigate('/sites')}>{error}</ResetPasswordBox>
      </div>
    );
  }

  if (passwordChangeSuccess) {
    return (
      <div className="absolute flex flex-col items-center justify-center h-full w-full left-0 top-0">
        <SingleCardWrapper>
          <Card radiusClass="rounded-2xl">
            <div className="mb-4">
              {t('passwordChanged', 'Password changed. You can now log in.')}
            </div>
            <Button
              type="submit"
              radiusClass="rounded-full"
              className="w-full h-14 text-base font-semibold"
              color="bg-vool-green"
              fontSize="text-base"
              fontWeight="font-semibold"
              shadow="shadow-none"
              onClick={() => setAuthOpen(true)}
            >
              {t('logIn', 'Login')}
            </Button>
          </Card>
        </SingleCardWrapper>
        <AuthModal
          open={authOpen}
          closeModal={() => {
            setAuthOpen(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="absolute flex flex-col items-center justify-center h-full w-full left-0 top-0">
      {loading ? (
        <CardSpinner />
      ) : (
        <SingleCardWrapper>
          <Card radiusClass="rounded-2xl">
            <form
              style={{ position: 'relative' }}
              className="space-y-3 w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                type="password"
                label={t('newPassword', 'New password')}
                error={Boolean(formState.errors?.password)}
                helpText={formState.errors?.password?.message as string}
                {...register('password')}
              />
              <Input
                type="password"
                label={t('confirmPassword', 'Confirm password')}
                error={Boolean(formState.errors?.passwordConfirmation)}
                helpText={formState.errors?.passwordConfirmation?.message as string}
                {...register('passwordConfirmation')}
              />

              <div className="pt-6 w-full">
                <Button
                  type="submit"
                  radiusClass="rounded-full"
                  className="w-full h-14 text-base font-semibold"
                  color="bg-vool-green"
                  fontSize="text-base"
                  fontWeight="font-semibold"
                  shadow="shadow-none"
                >
                  {t('savePassword', 'Save password')}
                </Button>
              </div>
            </form>
          </Card>
        </SingleCardWrapper>
      )}
    </div>
  );
};
