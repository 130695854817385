import { isAnyEvseAvailable } from 'handlers/device/device.handler';
import { Device } from 'models/device';
import { DeviceStatus } from 'models/device.enums';
import { ParkingAndReservation } from 'models/parking-and-reservation';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';
import { createSelector } from 'reselect';
import { selectAvailabilityFilters } from 'services/filters/selectors';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { RootState } from 'services/store';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { SiteDeviceMaxPowers } from '.';

export const selectSlice = (state: RootState) => state.sites;

export const selectPublicSites = createSelector(selectSlice, (slice) =>
  slice.publicSites.filter((site) => site.addressJson),
);

const isAvailableSite = (
  site: Site,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) =>
  site.status === 'active' &&
  site.devices.some((device) => {
    return (
      device.status === DeviceStatus.ONLINE &&
      isAnyEvseAvailable(device, activeTransaction, activeReservation)
    );
  });

export const selectPublicSitesFiltered = createSelector(
  selectPublicSites,
  selectAvailabilityFilters,
  selectActiveTransaction,
  selectActiveReservation,
  (sites, filters, activeTransaction, activeReservation) => {
    const { available, busy, unlisted } = filters;
    return sites.filter((site) => {
      if (available && busy && unlisted) {
        return true;
      }
      if (available && isAvailableSite(site, activeTransaction, activeReservation)) {
        return true;
      }

      if (busy && !isAvailableSite(site, activeTransaction, activeReservation)) {
        return true;
      }

      if (unlisted && site.isUnlisted) {
        return true;
      }

      return false;
    });
  },
);

export const selectActiveSiteAndCharger = createSelector(
  selectSlice,
  (slice) => slice.activeSiteAndCharger,
);
export const selectSiteDeviceMaxPowers = createSelector(
  selectSlice,
  (slice) => slice.siteDeviceMaxPowers,
);

export const selectSiteDeviceMaxPowerByUuid = (uuid: string) =>
  createSelector(
    selectSiteDeviceMaxPowers,
    (maxPowers: SiteDeviceMaxPowers) => maxPowers[uuid] || 22,
  );

export const selectHourlyPrices = createSelector(selectSlice, (slice) => slice.hourlyPrices);

export const selectSiteHourlyPrices = (uuid: string) =>
  createSelector(selectHourlyPrices, (hourlyPrices) => hourlyPrices[uuid]);

export const selectSiteVatMultiplier = (uuid: string) =>
  createSelector(
    selectPublicSites,
    (sites: Site[]) => sites.find((site) => site.uuid === uuid)?.vatMultiplier,
  );

export const selectActiveSite = createSelector(selectSlice, (slice) => slice.activeSite);

export const selectDevice = (deviceUuid: string) =>
  createSelector(selectPublicSites, (sites) =>
    sites.flatMap(({ devices }) => devices).find((device: Device) => device.uuid === deviceUuid),
  );
