import { useTranslation } from 'react-i18next';

import { SwipeableDrawer } from '@mui/material';

import exitIcon from 'app/images/icons/exit.svg';
import { VehicleItem } from 'app/views/Vehicles/VehicleItem';
import { Car } from 'models/car';

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

type VehicleDrawerProps = {
  isOpen: boolean;
  vehicles: Car[];
  onVehicleItemClick: (id?: string) => void;
  close: () => void;
};

export const VehicleDrawer = ({
  isOpen,
  vehicles,
  onVehicleItemClick,
  close,
}: VehicleDrawerProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <SwipeableDrawer
        open={isOpen}
        anchor="bottom"
        onClose={close}
        onOpen={() => {}}
        disableSwipeToOpen={false}
        hideBackdrop
        disableEnforceFocus
        onClick={close}
        ModalProps={{
          keepMounted: true,
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        classes={{
          paper: 'rounded-t-3xl',
        }}
        PaperProps={{
          sx: {
            width: '100%',
            height: 'auto !important',
          },
        }}
        sx={{
          zIndex: '20 !important',
        }}
      >
        <div className="pt-4">
          <div className="flex justify-center">
            <div className="w-14 bg-gray-200 h-1 rounded-full"></div>
          </div>
          <div className="p-4 font-TwkEverett text-2xl">
            {t('chooseYourVehicle', 'Choose your vehicle')}
          </div>
          <div className="pl-4 pt-4 flex flex-col space-y-3">
            <ul className="flex flex-col text-xl space-y-3">
              {vehicles.map((vehicle) => (
                <VehicleItem vehicle={vehicle} key={vehicle.id} onClick={onVehicleItemClick} />
              ))}
            </ul>
            <ul className="flex flex-col text-xl pb-8">
              <button
                className="flex space-x-4 items-center"
                onClick={() => onVehicleItemClick(undefined)}
              >
                <div className="flex border bg-primary rounded-2xl w-14 h-14">
                  <img className="flex m-4" src={exitIcon} alt="exit" />
                </div>
                <div>
                  <div className="flex text-base space-x-2">
                    {t('unknownVehicle', 'Unknown vehicle')}
                  </div>
                </div>
              </button>
            </ul>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};
