import { createAction } from '@reduxjs/toolkit';

import { Device } from 'models/device';
import { Site } from 'models/site';

import { ActiveSiteAndChargerPayload } from '.';

export const updatePublicDeviceFromPusher = createAction<{ device: Device }>(
  'sites/updatePublicDeviceFromPusher',
);
export const updateActiveSiteAndCharger = createAction<ActiveSiteAndChargerPayload>(
  'sites/updateActiveSiteAndCharger',
);

export const updateSitesDistance = createAction<Site[]>('sites/updateSitesDistance');

export const updateActiveSite = createAction<Site | undefined>('sites/updateActiveSite');

export const updateActiveSiteByUuid = createAction<string>('sites/updateActiveSiteByUuid');
