import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { Checkbox } from 'components/atoms/checkboxes/Checkbox';
import { Grid } from 'components/grid/Grid';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { useForm } from 'hooks/user-form';
import apiSlice from 'services/api';
import { selectCompany, selectUser } from 'services/auth/selectors';
import * as yup from 'yup';

import { CountryAutocomplete } from './CountryAutocomplete';

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required*'),
    email: yup.string(),
    address1: yup.string().required('Required*'),
    address2: yup.string(),
    city: yup.string().required('Required*'),
    state: yup.string().required('Required*'),
    country: yup.string().required('Select country from the list*'),
    zip: yup.string(),
    taxIdNumber: yup.string(),
  })
  .required();

export const CompanyInfo = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, formError, setFormError, setValue, clearErrors } =
    useForm({ schema });
  const user = useSelector(selectUser);
  const [showCompanyInfo, setShowCompanyInfo] = useState(Boolean(user?.companyBillingFlag));
  const company = useSelector(selectCompany);
  const [
    editBillingDetails,
    { isLoading: editBillingDetailsSaving, isSuccess: editBillingDetailsSuccess },
  ] = apiSlice.useEditBillingDetailsMutation();

  useEffect(() => {
    register('country');
  }, []);

  useEffect(() => {
    if (company?.uuid) {
      setValue('name', company.name);
      setValue('address1', company.address1);
      setValue('address2', company.address2);
      setValue('city', company.city);
      setValue('state', company.state);
      setValue('zip', company.zip);
      setValue('taxIdNumber', company.taxIdNumber);
    }
  }, [company]);

  const onSubmit = async ({
    name,
    email: companyEmail,
    address1,
    address2,
    city,
    state,
    country,
    zip,
    taxIdNumber,
  }: any) => {
    const email = companyEmail || user?.email;
    const saveData = {
      name,
      email,
      address1,
      address2,
      city,
      state,
      country,
      zip,
      taxIdNumber,
    };

    try {
      setFormError('');
      const response = await editBillingDetails({
        billingDetails: saveData,
      });

      if ('error' in response) {
        setFormError(
          // TODO: Fix error response handling
          (response as any)?.error?.data?.message || 'Something went wrong! Please try again',
        );
      } else if (!company?.uuid) {
        await editBillingDetails({ companyBillingFlag: 1 });
      }
      // TODO: Not sure if this catch triggers and how error should be handled
    } catch (e: unknown) {
      setFormError(e as string);
    }
  };

  const saveBillingPreference = async (checked: boolean) => {
    if (company?.uuid) {
      await editBillingDetails({ companyBillingFlag: +checked });
    }
  };

  return (
    <div className="grid md:grid-cols-4 sm:grid-cols-1 text-base font-normal">
      <Checkbox
        name="companyBillingFlag"
        label={t('useCompanyBilling', 'Use company billing details')}
        value={1}
        checked={showCompanyInfo}
        inputProps={{
          checked: showCompanyInfo,
        }}
        onChange={({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
          setShowCompanyInfo(checked);
          saveBillingPreference(checked);
        }}
      />

      {showCompanyInfo ? (
        <form
          className="px-2 py-1 md:col-span-2 sm:col-span-1 space-y-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            label={`${t('companyName', 'Company name')} *`}
            error={Boolean(formState.errors?.name)}
            helpText={formState.errors?.name?.message as string}
            {...register('name')}
          />
          <Input
            label={`${t('companyEmail', 'Company email address')} *`}
            error={Boolean(formState.errors?.email)}
            helpText={formState.errors?.email?.message as string}
            inputProps={{
              placeholder: user?.email,
            }}
            {...register('email')}
          />
          <Grid maxCol={2}>
            <Input
              label={`${t('address1', 'Aadress line 1')} *`}
              error={Boolean(formState.errors?.address1)}
              helpText={formState.errors?.address1?.message as string}
              {...register('address1')}
            />
            <Input
              label={t('address2', 'Aadress line 2')}
              error={Boolean(formState.errors?.address2)}
              helpText={formState.errors?.address2?.message as string}
              {...register('address2')}
            />
          </Grid>
          <Grid maxCol={2}>
            <Input
              label={`${t('city', 'City')} *`}
              error={Boolean(formState.errors?.city)}
              helpText={formState.errors?.city?.message as string}
              {...register('city')}
            />
            <Input
              label={`${t('zip', 'Zip')} *`}
              error={Boolean(formState.errors?.zip)}
              helpText={formState.errors?.zip?.message as string}
              inputProps={{
                placeholder: '123456',
              }}
              {...register('zip')}
            />
          </Grid>
          <Grid maxCol={2}>
            <CountryAutocomplete
              label={`${t('country', 'Country')} *`}
              name="country"
              error={Boolean(formState.errors?.country)}
              helpText={formState.errors?.country?.message as string}
              value={company?.country ?? ''}
              onChange={(val) => {
                setValue('country', val);
                clearErrors('country');
              }}
            />
            <Input
              label={`${t('state', 'State')} *`}
              error={Boolean(formState.errors?.state)}
              helpText={formState.errors?.state?.message as string}
              {...register('state')}
            />
          </Grid>
          <Grid maxCol={2}>
            <Input
              label={t('vat', 'VAT no')}
              error={Boolean(formState.errors?.taxIdNumber)}
              helpText={formState.errors?.taxIdNumber?.message as string}
              inputProps={{
                placeholder: '123456',
              }}
              {...register('taxIdNumber')}
            />
          </Grid>
          {formError && <div className="flex justify-end text-red-500 text-sm">{formError}</div>}
          <div className="flex justify-end items-center">
            {editBillingDetailsSaving && (
              <div className="mr-8 relative">
                <OverflowSpinner size={4} />
              </div>
            )}
            <Button type="submit">
              {editBillingDetailsSuccess ? t('saved', 'Saved') : t('save', 'Save')}
            </Button>
          </div>
        </form>
      ) : null}
    </div>
  );
};
