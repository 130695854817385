import React from 'react';

type CheckboxProps = {
  name: string;
  label: string;
  description?: React.ReactNode;
  value?: number;
  inputProps?: Record<string, unknown>;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, description, name, value = 0, inputProps, checked, onChange }, ref) => (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          ref={ref}
          id={`checkbox-${name}`}
          aria-describedby={`${name}-description`}
          name={name}
          type="checkbox"
          checked={checked}
          className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
          value={value}
          onChange={onChange}
          {...inputProps}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={`checkbox-${name}`} className="font-medium text-gray-700">
          {label}
        </label>
        {description && (
          <span id={`${name}-description`} className="text-gray-500">
            <span className="sr-only">{label}</span>
            &nbsp;
            {description}
          </span>
        )}
      </div>
    </div>
  ),
);

Checkbox.displayName = 'Checkbox';
