import { initReactI18next } from 'react-i18next';

import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';

const availableLanguages = ['et', 'en', 'ru'];
const fallbackLang = 'en';
const conf = JSON.parse(process.env.REACT_APP_LOCIZE ?? '{}')[
  process.env.REACT_APP_CONF_FILE ?? ''
];

// TODO: Whitelist doesn't seem to be supported
const options: InitOptions = {
  fallbackLng: fallbackLang,
  saveMissing: true,
  saveMissingTo: 'all',
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['cookie'],
    // checkWhitelist: true,
  },
  debug: false,
  // whitelist: availableLanguages,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    projectId: conf.projectId,
    apiKey: conf.apiKey,
  },
};

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init(options);

export { availableLanguages, fallbackLang as fallbackLanguage };
export default i18n;
