import audiLogo from 'app/images/icons/vehicles/audi.png';
import bmwLogo from 'app/images/icons/vehicles/bmw.png';
import cupraLogo from 'app/images/icons/vehicles/cupra.png';
import hyundaiLogo from 'app/images/icons/vehicles/hyundai.png';
import kiaLogo from 'app/images/icons/vehicles/kia.png';
import nissanLogo from 'app/images/icons/vehicles/nissan.png';
import peugeotLogo from 'app/images/icons/vehicles/peugeot.png';
import porscheLogo from 'app/images/icons/vehicles/porsche.png';
import renaultLogo from 'app/images/icons/vehicles/renault.png';
import skodaLogo from 'app/images/icons/vehicles/skoda.png';
import teslaLogo from 'app/images/icons/vehicles/tesla.png';
import vwLogo from 'app/images/icons/vehicles/volkswagen.png';
import volvoLogo from 'app/images/icons/vehicles/volvo.png';

import { ChargerIcon } from '../PublicSites/ChargerIcon';

type VehicleIconProps = {
  brand?: string;
  className?: string;
};

export const VehicleIcon = ({ brand, className }: VehicleIconProps) => {
  const logoMap = {
    volvo: volvoLogo,
    tesla: teslaLogo,
    audi: audiLogo,
    skoda: skodaLogo,
    renault: renaultLogo,
    volkswagen: vwLogo,
    bmw: bmwLogo,
    porshe: porscheLogo,
    cupra: cupraLogo,
    hunday: hyundaiLogo,
    kia: kiaLogo,
    nissan: nissanLogo,
    peugeot: peugeotLogo,
  };
  const carLogo = logoMap[brand?.toLowerCase() as keyof typeof logoMap];

  if (!carLogo) {
    return <ChargerIcon stroke="#999898" />;
  }

  return <img className={className} src={carLogo} alt={brand} />;
};
