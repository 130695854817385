export interface LineItem {
  title: string;
  costInEuros: number;
  details: {
    type: string;
    amount: string;
  }[];
}

type LineItemProps = {
  lineItem: LineItem;
};

export const LineItem = ({ lineItem }: LineItemProps) => (
  <div className="flex flex-col text-base pt-4 pb-4 border-b-2">
    <div className="flex justify-between flex-row">
      <div>{lineItem.title}</div>
      <div>{lineItem.costInEuros.toFixed(2)}€</div>
    </div>
    {lineItem.details.map((detail) => (
      <div
        className="text-vool-gray-400 flex justify-between text-sm flex-row"
        key={lineItem.title}
      >
        <div>{detail.type}</div>
        <div>{detail.amount}</div>
      </div>
    ))}
  </div>
);
