import { updatePublicDeviceFromPusher } from 'services/sites/actions';

import { DataHandler } from '../data.handler';

export class PusherDevicesDataHandler {
  data;
  meta;
  action;
  object;

  constructor({ data, meta, object }: any) {
    this.action = meta.action;
    this.object = object;
    this.data = data;
    this.meta = meta;
  }

  updateDevice() {
    const device = {
      [this.meta.fieldName]: this.meta.fieldValue,
      visibility: this.meta.visibility,
      status: this.data.current.status,
      activePower: this.data.current.active_power,
      ocppStatus: this.data.current.ocppStatus,
      transactionStatus: this.data.current.transactionStatus,
      amount: this.data.current.energy_imported
        ? this.data.current.energy_imported / 1000
        : undefined,
    };
    const cleanDataDevice = { device: DataHandler.cleanDataObject(device) };
    return [updatePublicDeviceFromPusher(cleanDataDevice)];
  }
}
