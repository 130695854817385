import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { removeCredentials } from 'services/auth';
import { selectUser } from 'services/auth/selectors';

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    trackAmplitudeEvent(AMPLITUDE_EVENTS.LOGOUT, getAmplitudeEventPropertiesForUser(user));
    dispatch(removeCredentials());
    navigate('/login');
  }, []);

  return null;
};
