import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, useMediaQuery } from '@mui/material';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import costIcon from 'app/images/icons/costPrimary.svg';
import energySuppliedIcon from 'app/images/icons/energySuppliedPrimary.svg';
import timePrimaryIcon from 'app/images/icons/timePrimary.svg';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { addMonths, isSameMonth, subMonths } from 'date-fns';
import { sumBy } from 'lodash';
import { Transaction } from 'models/transaction';
import moment from 'moment';
import apiSlice from 'services/api';
import { selectUser } from 'services/auth/selectors';
import { selectFooterNotification } from 'services/notifications/selectors';
import { selectTransactions } from 'services/transactions/selectors';

import { ChargingHistoryList } from './ChargingHistoryList';
import { NoTransactions } from './NoTransactions';

export const ChargeHistory = () => {
  const self = useSelector(selectUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const transactions = useSelector(selectTransactions);
  const [selectedMonthTransactions, setSelectedMonthTransactions] = useState<Transaction[]>();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const isMobile = useMediaQuery('(max-width: 768px)');
  const footerNotification = useSelector(selectFooterNotification);

  const updateSelectedMonthTransactions = () => {
    setSelectedMonthTransactions(
      transactions.list.filter(
        (transaction) =>
          transaction.userUuid === self?.uuid &&
          isSameMonth(new Date(transaction.startTime), selectedMonth),
      ),
    );
  };

  useEffect(() => {
    updateSelectedMonthTransactions();
  }, [transactions, selectedMonth]);

  const selectPreviousMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  const selectNextMonth = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const totalRevenueWithVAT = selectedMonthTransactions?.reduce((acc, transaction) => {
    const revenueWithVAT = transaction.monetaryRevenueWithVAT ?? 0;
    const reservationCost = transaction.reservationCost ?? 0;
    const parkingCost = transaction.parkingCost ?? 0;
    const totalCost = reservationCost + parkingCost + revenueWithVAT;
    const monetaryRevenueWithVAT = totalCost < 0.5 && totalCost > 0 ? 0.5 : totalCost;
    return acc + monetaryRevenueWithVAT;
  }, 0);

  const totalAmount = sumBy(selectedMonthTransactions, 'amount') / 1000;

  const { isFetching, isSuccess } = apiSlice.useCustomerTransactionsQuery(undefined, {
    skip: !self?.uuid,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  if (!isSuccess || isFetching) {
    return (
      <div className="relative h-20">
        <OverflowSpinner size={16} />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row items-center pb-8">
        <button type="button" className="h-10 w-10" onClick={() => navigate('/sites')}>
          <img src={arrowRightIcon} style={{ transform: 'scaleX(-1)' }} alt="Go back" />
        </button>
        <h3 className="text-2xl leading-6 font-normal text-app-header-link">
          {t('chargingHistory', 'Charging history')}
        </h3>
      </div>
      <div className="w-full sm:justify-center flex items-center justify-between">
        <button
          type="button"
          className="h-8 w-8 flex items-center justify-center bg-vool-gray-100 rotate-180 border border-vool-gray-100 rounded-full"
          onClick={selectPreviousMonth}
        >
          <img src={arrowRightIcon} alt="previous" className="w-6 h-6" />
        </button>
        <div className="mx-4">{moment(selectedMonth).format('MMMM YYYY')}</div>
        <button
          type="button"
          className="h-8 w-8 flex items-center justify-center bg-vool-gray-100 border border-vool-gray-100 rounded-full"
          onClick={selectNextMonth}
        >
          <img src={arrowRightIcon} alt="next" className="w-6 h-6" />
        </button>
      </div>
      {!isMobile ? (
        <div className="font-semibold text-lg pt-10 pb-4">{t('overview', 'Overview')}</div>
      ) : (
        <div className="font-semibold text-lg pt-10 pb-4">
          {moment(selectedMonth).format('MMMM')} {t('overview', 'Overview')}
        </div>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-3 gap-y-3">
        <div className="bg-vool-gray-100 h-[152px] rounded-2xl">
          <div className="p-4">
            <img src={energySuppliedIcon} alt="energy" />
          </div>
          <div className="pl-4 pt-2">
            <div>
              <div className="text-base">kwh</div>
              <div className="text-2xl -m-0.5">{totalAmount.toFixed(2)}</div>
              <div className="text-sm text-vool-gray-400 font-light">
                {t('consumption', 'Consumption')}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-vool-gray-100 h-[152px] rounded-2xl">
          <div className="p-4">
            <img src={costIcon} alt="cost" />
          </div>
          <div className="pl-4 pt-2">
            <div>
              <div className="text-base">€</div>
              <div className="text-2xl -m-0.5"> {totalRevenueWithVAT?.toFixed(2)}</div>
              <div className="text-sm text-vool-gray-400 font-light">{t('cost', 'Cost')}</div>
            </div>
          </div>
        </div>
        <div className="bg-vool-gray-100 h-[152px] rounded-2xl">
          <div className="p-4">
            <img src={timePrimaryIcon} alt="time" />
          </div>
          <div className="pl-4 pt-7">
            <div className="text-2xl -m-0.5">
              {selectedMonthTransactions?.length}
              <div className="text-sm text-vool-gray-400 font-light">
                {selectedMonthTransactions?.length === 1
                  ? t('session', 'Session')
                  : t('sessions', 'Sessions')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Typography mt={5} variant="p18">
        {t('history', 'History')}
      </Typography>
      <Box mb={`${16 + footerNotification.height}px`}>
        {selectedMonthTransactions?.length ? (
          <ChargingHistoryList transactions={selectedMonthTransactions} />
        ) : (
          <NoTransactions />
        )}
      </Box>
    </div>
  );
};
