import { useEffect, useState } from 'react';

import moment from 'moment';

const calculateFormattedTimeFrom = (datetime?: Date | moment.Moment) => {
  const now = moment();
  const start = moment(datetime ?? now);
  const duration = moment.duration(now.diff(start));
  return [Math.floor(duration.asHours()), duration.minutes(), duration.seconds()]
    .map((value) => value.toLocaleString('en-US', { minimumIntegerDigits: 2 }))
    .join(':');
};

type DurationCounterProps = {
  datetime?: Date | moment.Moment;
  maxTimeSeconds?: number;
  onMaxTimeAction?: () => void;
};

export const DurationCounter = ({
  datetime,
  maxTimeSeconds = 0,
  onMaxTimeAction,
}: DurationCounterProps) => {
  const [timeLeft, setTimeLeft] = useState(calculateFormattedTimeFrom());
  const [maxTimeActionCalled, setMaxTimeActionCalled] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateFormattedTimeFrom(datetime));
      if (!maxTimeActionCalled && moment().diff(moment(datetime), 'seconds') >= maxTimeSeconds) {
        onMaxTimeAction?.();
        setMaxTimeActionCalled(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <>{timeLeft}</>;
};
