import { ApiEndpointBuilder } from 'services/api/endpoints';

import { SiteDeviceMaxPowerResponse, SiteHourlyPricesResponse, SitesResponse } from './responses';

type SiteDeviceMaxPowerArgs = { uuid: string };

export const buildSitesEndpoints = (builder: ApiEndpointBuilder) => ({
  publicSites: builder.query<SitesResponse, undefined>({
    query: () => ({
      url: '/sites/user',
      method: 'GET',
    }),
  }),
  getSiteDeviceMaxPower: builder.query<SiteDeviceMaxPowerResponse, SiteDeviceMaxPowerArgs>({
    query: ({ uuid }) => ({
      url: `/sites/${uuid}/deviceMaxPower`,
      method: 'GET',
    }),
  }),
  getSiteHourlyPrices: builder.query<SiteHourlyPricesResponse, string>({
    query: (uuid) => ({
      url: `/sites/${uuid}/hourlyPrices`,
      method: 'GET',
    }),
  }),
});
