import { useState } from 'react';
import { useForm as useReactHookForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

interface FormProps {
  schema: Yup.AnyObjectSchema;
}

export const useForm = ({ schema, ...rest }: FormProps) => {
  const [formError, setFormError] = useState('');

  return {
    formError,
    setFormError,
    ...useReactHookForm({
      resolver: yupResolver(schema),
      ...rest,
    }),
  };
};
