import { ApiEndpointBuilder } from 'services/api/endpoints';

// TODO: Code here conflicts with schema in the back-end
interface PortalConfigResponse {
  success: boolean;
  settingsJson: any;
}

export const buildPortalEndpoints = (builder: ApiEndpointBuilder) => ({
  getPortalConfig: builder.query<PortalConfigResponse, string>({
    query: (domain) => ({
      url: `/portal/${domain}`,
      method: 'GET',
    }),
  }),
});
