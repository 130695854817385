import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Global } from '@emotion/react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { mdiCrosshairsGps } from '@mdi/js';
import Icon from '@mdi/react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton } from '@mui/material';

import chargerOn from 'app/images/icons/chargerOn.svg';
import roadMapIcon from 'app/images/icons/roadMap.png';
import satelliteMapIcon from 'app/images/icons/satelliteMap.png';
import siteMarker from 'app/images/icons/siteMarker.svg';
import userLocationIcon from 'app/images/icons/userLocation.svg';
import { Alert } from 'app/views/Notifications/Alert';
import { Spinner } from 'components/atoms/Spinner';
import { googleApiKey } from 'config/consts';
import GoogleMapReact from 'google-map-react';
import { toggleFiltersOpen } from 'services/filters';
import { selectBottomSheetHeight } from 'services/portal/selectors';
import { updateActiveSite, updateSitesDistance } from 'services/sites/actions';
import {
  selectActiveSite,
  selectPublicSites,
  selectPublicSitesFiltered,
} from 'services/sites/selectors';

import { mapStyle } from './mapStyle';

// Tallinn
const DEFAULT_CENTER = {
  lat: 59.43696079999999,
  lng: 24.7535746,
};
const DEFAULT_ZOOM = 14;

type GoogleMapProps = {
  destination?: google.maps.Place;
};

export const GoogleMap = ({ destination }: GoogleMapProps) => {
  const dispatch = useDispatch();

  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const [currentPosition, setCurrentPosition] = useState<{ lat: number; lng: number }>();
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [geolocationStatus, setGeolocationStatus] = useState('');
  const [locationLoading, setLocationLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [map, setMap] = useState<google.maps.Map>();
  const [mapType, setMapType] = useState('roadmap');
  const [markerClusterer, setMarkerClusterer] = useState<MarkerClusterer>();
  const [userMarker, setUserMarker] = useState<google.maps.Marker>();

  const sites = useSelector(selectPublicSites);
  const activeSite = useSelector(selectActiveSite);
  const filteredSites = useSelector(selectPublicSitesFiltered);
  const bottomSheetHeight = useSelector(selectBottomSheetHeight);

  useEffect(() => {
    if (markerClusterer) {
      const markers: google.maps.Marker[] = [];
      filteredSites.forEach((site) => {
        const marker = new google.maps.Marker({
          icon: chargerOn,
          position: site.addressJson as google.maps.LatLng,
        });
        marker.addListener('click', () => dispatch(updateActiveSite(site)));
        markers.push(marker);
      });
      markerClusterer.clearMarkers();
      markerClusterer.addMarkers(markers);
    }
  }, [filteredSites]);

  const getDistances = (cp: { lat: number; lng: number }) => {
    const destinations = sites.map((s) => s.addressJson as google.maps.LatLng);
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [cp],
        destinations,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK && response) {
          const [row] = response.rows;
          const sitesWithDistance = sites.map((site, index) => ({
            ...site,
            distance: row.elements[index].distance,
          }));
          dispatch(updateSitesDistance(sitesWithDistance));
        }
      },
    );
  };

  useEffect(() => {
    if (currentPosition) {
      getDistances(currentPosition);
    }
  }, [sites.length]);

  useEffect(() => {
    if (!userMarker && currentPosition) {
      setUserMarker(
        new google.maps.Marker({
          map,
          icon: userLocationIcon,
          position: currentPosition,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + sites.length,
          clickable: false,
        }),
      );
    } else if (map && userMarker && currentPosition) {
      userMarker.setPosition(currentPosition);
    }
  }, [currentPosition]);

  const setCurrentLocation = (errorCallback: () => void) => {
    if (navigator.geolocation) {
      setLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          getDistances(pos);
          setGeolocationStatus('');
          setCurrentPosition(pos);
          setCenter(undefined);
          setCenter(pos);
          setZoom(DEFAULT_ZOOM);
          setLocationLoading(false);
        },
        ({ message }) => {
          setGeolocationStatus(message);
          setLocationLoading(false);
          errorCallback?.();
        },
      );
    }
  };

  const centerMarker = (addressJson: any) => {
    setCenter({ lat: addressJson.lat, lng: addressJson.lng });
    setZoom((prev) => Math.max(prev, DEFAULT_ZOOM));
  };

  const setDestinationToNavigate = (
    newDestination: google.maps.Place,
    errorCallback: () => void,
  ) => {
    if (!directionsRenderer || !map) return;
    const directionsService = new window.google.maps.DirectionsService();
    directionsRenderer.setMap(null);
    directionsRenderer.setMap(map);
    directionsRenderer.setOptions({ suppressMarkers: true });
    directionsService.route(
      {
        origin: currentPosition ?? DEFAULT_CENTER,
        destination: newDestination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          setGeolocationStatus(`Something went wrong! Please try again: ${result}`);
          errorCallback();
        }
      },
    );
  };

  useEffect(() => {
    if (activeSite?.addressJson) {
      centerMarker(activeSite.addressJson);
    }
  }, [activeSite]);

  useEffect(() => {
    if (destination) {
      setDestinationToNavigate(destination, () => setAlert(true));
    }
  }, [destination]);

  return (
    <div className="relative w-full h-full">
      {alert && geolocationStatus && (
        <div className="absolute top-0 w-full z-10">
          <Alert content={geolocationStatus} close={() => setAlert(false)} />
        </div>
      )}
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        onClick={({ event }) => {
          const { target } = event;
          if (target.closest('[name="infoWindow"]')) {
            return;
          }
        }}
        bootstrapURLKeys={{ key: googleApiKey }}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        zoom={zoom}
        center={center}
        onDrag={() => {
          setCenter(undefined);
        }}
        onGoogleApiLoaded={({ map }) => {
          if (!activeSite) {
            setCurrentLocation(() => setAlert(true));
          }
          setDirectionsRenderer(new google.maps.DirectionsRenderer());
          setMap(map);
          const markers: google.maps.Marker[] = [];
          sites.forEach((site) => {
            const marker = new google.maps.Marker({
              icon: chargerOn,
              position: site.addressJson as google.maps.LatLng,
            });
            marker.addListener('click', () => dispatch(updateActiveSite(site)));
            markers.push(marker);
          });
          const renderer = {
            render: ({ count, position }: any) =>
              new google.maps.Marker({
                label: {
                  text: String(count),
                  color: 'white',
                  className: 'font-semibold',
                },
                position,
                icon: siteMarker,
                clickable: true,
                // adjust zIndex to be above other markers
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              }),
          };
          setMarkerClusterer(new MarkerClusterer({ map, markers, renderer }));
        }}
        onZoomAnimationEnd={(z) => setZoom(z)}
        options={{
          fullscreenControl: false,
          draggable: true,
          styles: mapStyle,
          gestureHandling: 'greedy',
          mapTypeId: mapType,
        }}
      >
        <Global
          styles={{
            '.vool-map-buttons,.gm-bundled-control-on-bottom': {
              '@media only screen and (max-width: 568px)': {
                bottom: `${(bottomSheetHeight ?? 0) + 90}px !important`,
              },
            },
            '.vool-map-buttons': {
              right: 2,
            },
          }}
        />
      </GoogleMapReact>
      <div className="vool-map-buttons absolute sm:bottom-28 flex flex-col items-center justify-center pb-1">
        <IconButton
          color="inherit"
          disableRipple
          onClick={() => dispatch(toggleFiltersOpen())}
          sx={{
            backgroundColor: 'white',
            marginBottom: '8px',
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
        <button
          onClick={() => {
            setCurrentLocation(() => setAlert(true));
          }}
          type="button"
          className="w-10 h-10 bg-white rounded-full p-1 flex items-center justify-center shadow-sm"
        >
          {locationLoading ? (
            <Spinner size={4} backgroundColor="bg-transparent" />
          ) : (
            <Icon path={mdiCrosshairsGps} size="24px" />
          )}
        </button>
        <button
          type="button"
          onClick={() => {
            setMapType((prev) => (prev === 'roadmap' ? 'satellite' : 'roadmap'));
          }}
        >
          <img
            className="w-14 h-14"
            src={mapType === 'roadmap' ? satelliteMapIcon : roadMapIcon}
            alt="mapTypeId"
          />
        </button>
      </div>
    </div>
  );
};
