import { ArrowLeftIcon, LocationMarkerIcon } from '@heroicons/react/outline';

import { Device } from 'models/device';
import { Site } from 'models/site';

type ChargerHeaderProps = {
  site: Site;
  device: Device;
  closeSlideBar: () => void;
};

export const ChargerHeader = ({ site, device, closeSlideBar }: ChargerHeaderProps) => (
  <div className="sticky z-30 top-0 flex items-center justify-start py-4 pr-4 pl-14 sm:pr-6 sm:pl-16 mb-2 bg-white">
    <button
      type="button"
      className="absolute top-6 left-4 sm:left-6 rounded-full bg-white p-2 text-black"
      onClick={closeSlideBar}
    >
      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
    </button>
    <div className="flex flex-col w-full">
      <span className="font-normal text-2xl overflow-wrap-anywhere">{device.name}</span>
      <div className="flex flex-col xs:flex-row items-start justify-between text-sm text-vool-gray-400  w-full">
        <span>{site.address}</span>
        {site.distance && (
          <span className="flex items-center whitespace-nowrap justify-start xs:justify-end space-x-1 w-20">
            <LocationMarkerIcon className="h-4 w-4 mr-1" />
            {site.distance.text}
          </span>
        )}
      </div>
    </div>
  </div>
);
