import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import InvoiceDownloadIcon from 'app/images/icons/invoiceDownload.svg';
import { Spinner } from 'components/atoms/Spinner';
import { format } from 'date-fns';
import apiSlice from 'services/api';
import { selectInvoices } from 'services/billing/selectors';
import { selectTransactions } from 'services/transactions/selectors';

export const Invoices = () => {
  const { t } = useTranslation();
  const invoices = useSelector(selectInvoices);
  const transactions = useSelector(selectTransactions);
  apiSlice.useCustomerTransactionsQuery();
  const { isSuccess: invoicesSuccess, isFetching: invoicesFetching } = apiSlice.useGetInvoicesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (invoicesFetching || !invoicesSuccess) {
    return <Spinner size={14} />;
  }

  if (invoices.length < 1) {
    return (
      <div className="py-5 border bg-white shadow-sm flex items-center justify-center font-normal text-base rounded-2xl">
        {t('noInvoices', 'No invoices to show')} 🤷‍♂️
      </div>
    );
  }

  return (
    <Box>
      {invoices.map((invoice) => {
        const transaction = transactions.list.find(
          (transaction) => transaction.stripeInvoiceId === invoice.id,
        );

        return (
          <Box
            key={invoice.id}
            display="flex"
            alignItems="center"
            gap={2}
            py={1.5}
            className="border-b border-zinc"
          >
            {invoice.hosted_invoice_url && (
              <Box
                onClick={() => window.open(invoice.hosted_invoice_url ?? '', '_blank')}
                sx={{ cursor: 'pointer' }}
              >
                <img src={InvoiceDownloadIcon} alt="download" />
              </Box>
            )}
            <Box display="flex" flexDirection="column" flex={1}>
              <Box>
                <Typography variant="p16">{transaction?.siteName || 'Unknown Site'}</Typography>
              </Box>
              <Box>
                <Typography variant="p14" color="gray">
                  {format(new Date(invoice.created * 1000), 'MMMM dd, yyyy HH:mm')}
                </Typography>
              </Box>
            </Box>
            <Typography variant="p16">
              <Box>{(invoice.subtotal / 100).toFixed(2)} €</Box>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
