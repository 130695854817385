import { browserName, fullBrowserVersion, osName, osVersion } from 'react-device-detect';

import feedbackIcon from 'app/images/icons/feedbackIcon.svg';

export const SendFeedbackButton = () => (
  <a
    href={`mailto:support+public@vool.com?subject=Public charging site feedback&body=%0a%0a%0aOS ${osName}:${osVersion}, browser ${browserName}:${fullBrowserVersion}`}
    className="h-10 w-10 flex items-center justify-center rounded-full bg-raisingBlack "
  >
    <img src={feedbackIcon} alt="feedback" className="mt-0.5" />
  </a>
);
