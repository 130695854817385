import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import { GoogleAuth } from 'components/google/GoogleAuth';

type AuthStep1Props = {
  closeModal: () => void;
  goToNextStep: () => void;
};

export const AuthStep1 = ({ closeModal, goToNextStep }: AuthStep1Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <span className="text-32px font-TwkEverett">{t('hi there', 'Hi there!').toString()}</span>
      <span className="pt-2">{t('hiThereIntro', 'Let’s sign you in.').toString()}</span>
      <div className="pt-7 flex flex-col text-center sm:px-18">
        <GoogleAuth scaleForMobile={false} closeModal={closeModal} />
        <Button
          type="submit"
          className="w-full h-14 mt-4 rounded-full"
          fontSize="text-base"
          fontWeight="font-semibold"
          radiusClass="rounded-full"
          color="bg-vool-green"
          shadow="shadow-none"
          onClick={goToNextStep}
        >
          {t('continueWithEmail', 'Continue with Email').toString()}
        </Button>
      </div>
    </div>
  );
};
