import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LocationMarkerIcon } from '@heroicons/react/outline';
import { Box, Typography } from '@mui/material';

import { ChargerIcon } from 'app/views/PublicSites/ChargerIcon';
import { createEvses, isEvseAvailable } from 'handlers/device/device.handler';
import { SiteHandler } from 'handlers/site.handler';
import { uniqBy } from 'lodash';
import { ConnectorType } from 'models/device.enums';
import { Site } from 'models/site';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForSite } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { updateActiveSite } from 'services/sites/actions';
import { selectActiveSite, selectSiteDeviceMaxPowerByUuid } from 'services/sites/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { FastChargerIcon } from './FastChargerIcon';
import { OfflineSiteIcon } from './OfflineSiteIcon';

type SinglePublicSiteProps = {
  site: Site;
};

export const SinglePublicSite = ({ site }: SinglePublicSiteProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const siteHandler = new SiteHandler(site);
  // TODO: transaction handling issues here too
  const activeTransaction = useSelector(selectActiveTransaction);
  const siteDeviceMaxPower = useSelector(selectSiteDeviceMaxPowerByUuid(site.uuid));
  const evses = site.devices.flatMap((device) => createEvses(device));
  const onlineAvailableChargers = evses.filter((evse) => isEvseAvailable(evse, activeTransaction));
  const isSomeChargerCharging = siteHandler.isSomeChargerCharging(activeTransaction);
  const active = useSelector(selectActiveSite)?.uuid === site.uuid;

  const getStatusColor = () => {
    if (!onlineAvailableChargers.length) {
      return '#999898';
    }
    if (isSomeChargerCharging) {
      return '#EB4E20';
    }

    if (site.isUnlisted) {
      return '#407090';
    }

    return '#386957';
  };

  const statusColor = getStatusColor();

  const handleSelectActiveSite = () => {
    dispatch(updateActiveSite(site));
    trackAmplitudeEvent(
      AMPLITUDE_EVENTS.SELECT_SITE,
      getAmplitudeEventPropertiesForSite({ site, user }),
    );
  };

  const getSiteIcon = (statusColor: string) => {
    if (!onlineAvailableChargers.length) {
      return <OfflineSiteIcon />;
    }

    if (siteDeviceMaxPower > 22) {
      return <FastChargerIcon stroke={statusColor} />;
    }

    return <ChargerIcon stroke={statusColor} />;
  };

  const getDeviceConnectors = () => {
    const connectorTypes = site.devices
      ?.flatMap((device) => device.configOfConnectors)
      .flatMap(
        (connector) => ConnectorType[connector?.connectorType as keyof typeof ConnectorType],
      );
    return uniqBy(connectorTypes, (type) => type).join(', ');
  };

  const getMaxPowers = () => {
    const maxPowers = uniqBy(
      evses.map((evse) => evse.maxPowerKw),
      (power) => power,
    );
    if (maxPowers.length === 1) {
      return maxPowers[0];
    } else {
      const min = Math.min(...maxPowers);
      const max = Math.max(...maxPowers);
      return `${min} - ${max}`;
    }
  };

  return (
    <Box
      display="flex"
      p={2}
      gap={2}
      borderRadius="20px"
      onClick={handleSelectActiveSite}
      className="transition duration-300 ease-in-out "
      borderColor={active ? `${statusColor}` : 'white'}
      sx={{ background: '#F4F4F4', borderWidth: '2px', cursor: 'pointer' }}
    >
      <Box
        flexShrink={0}
        border={`3px solid ${statusColor}`}
        width={56}
        height={56}
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
      >
        {getSiteIcon(statusColor)}
      </Box>
      <Box display="flex" flexDirection="column" flex={1} sx={{ minWidth: 0 }}>
        <Typography variant="p16b" noWrap>
          {site.name}
        </Typography>
        <Typography variant="p14" noWrap>
          {t(
            'siteDevicesAvailabilitySummaryNoKw',
            'Available {{ onlineAvailableCount }} of {{ totalCount }}',
            {
              onlineAvailableCount: onlineAvailableChargers.length,
              totalCount: evses.length,
            },
          )}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          color="#999898"
        >
          <Typography variant="p14" textAlign="start" noWrap>
            {site.address}
          </Typography>
          {site?.distance && (
            <Box display="flex" flexShrink={0} alignItems="center" gap="6px">
              <LocationMarkerIcon className="h-4 w-4" aria-hidden="true" />
              <Typography variant="p14">{site.distance?.text}</Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          <Box
            display="flex"
            p="4px 8px"
            borderRadius="4px"
            height="24px"
            sx={{ background: '#D6D6D6' }}
          >
            <Typography variant="p12">{getMaxPowers()} kW</Typography>
          </Box>
          <Box
            display="flex"
            p="4px 8px"
            borderRadius="4px"
            height="24px"
            sx={{ background: '#D6D6D6' }}
          >
            <Typography variant="p12">{getDeviceConnectors()}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
