import { useState } from 'react';

import { Box, Snackbar, styled } from '@mui/material';

const MessageToast = styled(Snackbar)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 16,
  [theme.breakpoints.up('sm')]: {
    bottom: 16,
    left: 'auto',
    right: 'auto',
  },
  '& > :first-of-type': {
    width: '100%',
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 24px',
    },
    borderRadius: '20px',
    padding: '12px 16px',
    fontFamily: 'inherit',
  },
}));

export function useSnackbar() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const showSnackbar = (content: string) => {
    setMessage(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    showSnackbar,
    Snackbar: (
      <Box position="relative">
        <MessageToast open={open} onClose={handleClose} message={message} autoHideDuration={4000} />
      </Box>
    ),
  };
}
