import { useNavigate } from 'react-router-dom';

import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';

import paymentCardIcon from 'app/images/icons/paymentCardIcon.svg';
import { Card } from 'components/atoms/Card';
import { startCase } from 'lodash';
import { PaymentMethod } from 'services/billing';

type PaymentMethodCardProps = {
  defaultPaymentMethod: PaymentMethod;
};

export const PaymentMethodCard = ({ defaultPaymentMethod }: PaymentMethodCardProps) => {
  const navigate = useNavigate();

  return (
    <button type="button" onClick={() => navigate('/billing')}>
      <Card
        wrapperClass="px-4 sm:px-6 text-center pb-4"
        className="p-2 bg-vool-gray-100"
        full
        radiusClass="rounded-2xl"
        borderless
      >
        <div className="flex w-full text-left items-center">
          <div className="p-2">
            <img src={paymentCardIcon} alt="card" sizes="24px" className="text-primary" />
          </div>
          <div className="flex flex-grow p-2 flex-col">
            <span className="font-semibold">{startCase(defaultPaymentMethod?.card?.brand)}</span>
            <span className="text-sm text-raisingBlack">{`**** ${defaultPaymentMethod?.card?.last4}`}</span>
          </div>
          <div className="p-2">
            <Icon path={mdiArrowRight} size="24px" />
          </div>
        </div>
      </Card>
    </button>
  );
};
