import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import api from 'services/api';

import { removeCredentials } from '../auth';

export interface PaymentMethod {
  id: string;
  card: {
    brand: string;
    exp_month?: number;
    exp_year?: number;
    last4: string;
  };
  type: string;
  defaultFlag: 0 | 1;
}

export interface Invoice {
  id: string;
  number: string;
  amount_due?: number;
  amount_paid?: number;
  amount_remaining?: number;
  created: number;
  currency?: string;
  description?: string;
  paid?: boolean;
  period_start?: number;
  period_end?: number;
  status?: string;
  subtotal: number;
  tax?: number;
  total?: number;
  invoice_pdf?: string;
  hosted_invoice_url?: string;
}

interface BillingState {
  paymentMethods: PaymentMethod[];
  invoices: Invoice[];
  stripeSessionUrl?: string;
  publicStripeSessionUser: any;
}

const initialState: BillingState = {
  paymentMethods: [],
  invoices: [],
  stripeSessionUrl: undefined,
  publicStripeSessionUser: {},
};

const setStripeSessionUrl = (
  state: BillingState,
  { payload }: PayloadAction<{ sessionUrl: string }>,
) => {
  state.stripeSessionUrl = payload.sessionUrl;
};

const setPaymentMethods = (
  state: BillingState,
  { payload }: PayloadAction<{ paymentMethods: PaymentMethod[] }>,
) => {
  state.paymentMethods = payload.paymentMethods;
};

const setInvoices = (state: BillingState, { payload }: PayloadAction<{ invoices: Invoice[] }>) => {
  state.invoices = payload.invoices;
};

const setPaymentMethodAsDefault = (
  state: BillingState,
  { payload: { default: defaultPaymentMethodId } }: PayloadAction<{ default: string }>,
) => {
  const arr: PaymentMethod[] = state.paymentMethods.map((p) => ({
    ...p,
    defaultFlag: p.id === defaultPaymentMethodId ? 1 : 0,
  }));
  state.paymentMethods = arr;
};

const deleteCard = (
  state: BillingState,
  {
    payload: {
      paymentMethod: { id },
    },
  }: PayloadAction<{ paymentMethod: PaymentMethod }>,
) => {
  const cardIndex = state.paymentMethods.findIndex((card) => card.id === id);
  state.paymentMethods.splice(cardIndex, 1);
};

const clearState = () => initialState;

export const dataSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeCredentials, clearState);
    builder.addMatcher(api.endpoints.addPaymentMethod.matchFulfilled, setStripeSessionUrl);
    builder.addMatcher(api.endpoints.addPublicPaymentMethod.matchFulfilled, setStripeSessionUrl);
    builder.addMatcher(api.endpoints.getPaymentMethods.matchFulfilled, setPaymentMethods);
    builder.addMatcher(api.endpoints.getInvoices.matchFulfilled, setInvoices);
    builder.addMatcher(
      api.endpoints.setPaymentMethodAsDefault.matchFulfilled,
      setPaymentMethodAsDefault,
    );
    builder.addMatcher(api.endpoints.deletePaymentMethodEndpoint.matchFulfilled, deleteCard);
  },
});

export default dataSlice.reducer;
