import { useTranslation } from 'react-i18next';

import { Car } from 'models/car';

import { VehicleIcon } from './VehicleIcon';

type VehicleItemProps = {
  onClick: (id: string) => void;
  vehicle: Car;
};

export const VehicleItem = ({ vehicle, onClick }: VehicleItemProps) => {
  const { brand, model, carId, status } = vehicle;
  const batteryLevel = status?.batteryLevel;
  const { t } = useTranslation();

  return (
    <button className="flex space-x-4 items-center" onClick={() => onClick(carId)}>
      <div className="flex border items-center bg-vool-gray-100 rounded-2xl w-14 h-14">
        <VehicleIcon brand={brand} className="p-1" />
      </div>
      <div>
        <div className="flex space-x-2 text-base">
          <p>{brand}</p>
          <p>{model}</p>
        </div>
        <div className="flex justify-start text-sm">
          <p>
            {t('battery', 'Battery')}: {batteryLevel}%
          </p>
        </div>
      </div>
    </button>
  );
};
