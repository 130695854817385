import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCookie } from 'handlers/cookie.handler';
import apiSlice from 'services/api';

type StripeSessionComponentProps = {
  startCharge: () => void;
};

export const StripeSessionComponent = ({ startCharge }: StripeSessionComponentProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const token = getCookie('cu_token');
  const temporaryToken = getCookie('cu_temporaryToken');
  const searchParams = new URLSearchParams(search);
  const stripeSessionId = searchParams.get('session_id');
  const deviceUuid = searchParams.get('device');
  const siteUuid = searchParams.get('site');

  const [registerUser] = apiSlice.useRegisterAccountlessUserMutation();
  apiSlice.useSelfQuery(temporaryToken, {
    skip: !temporaryToken,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  useEffect(() => {
    if (stripeSessionId && !temporaryToken && !token) {
      registerUser({ stripeSessionId });
    }
  }, []);

  useEffect(() => {
    if ((temporaryToken || token) && deviceUuid && stripeSessionId) {
      startCharge();
      navigate({ search: `site=${siteUuid}&device=${deviceUuid}` });
    }
  }, [temporaryToken, deviceUuid]);

  return null;
};
