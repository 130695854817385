import { ApiEndpointBuilder } from 'services/api/endpoints';

import { CompanyResponse, LoginResponse, SelfResponse } from './responses';

export const buildAuthEndpoints = (builder: ApiEndpointBuilder) => ({
  register: builder.mutation({
    query: ({ name, email, password }) => ({
      url: 'auth/register',
      method: 'POST',
      body: { name, email, password },
    }),
  }),
  self: builder.query<SelfResponse, any>({
    query: () => ({
      url: 'users/self',
      method: 'GET',
    }),
  }),
  updateSelf: builder.mutation<SelfResponse, any>({
    query: (data) => ({
      url: 'users/self',
      method: 'PUT',
      body: data,
    }),
  }),
  login: builder.mutation<LoginResponse, any>({
    query: ({ email, password }) => ({
      url: 'auth/login',
      method: 'POST',
      body: { email, password },
    }),
  }),
  resetPassword: builder.mutation({
    query: ({ email }) => ({
      url: 'auth/reset-password',
      method: 'POST',
      body: { email },
    }),
  }),
  checkPasswordHash: builder.query({
    query: ({ token }) => ({
      url: 'auth/check-password-hash',
      method: 'POST',
      body: { token },
    }),
  }),
  changeResetPassword: builder.mutation({
    query: ({ password, token }) => ({
      url: 'auth/change-reset-password',
      method: 'POST',
      body: { password, token },
    }),
  }),
  googleLogin: builder.mutation({
    query: ({ email, name, password, idToken }) => ({
      url: 'auth/google-login',
      method: 'POST',
      body: {
        email,
        name,
        password,
        idToken,
      },
    }),
  }),
  registerAccountlessUser: builder.mutation({
    query: ({ stripeSessionId }) => ({
      url: 'auth/registerAccountlessUser',
      method: 'POST',
      body: { stripeSessionId },
    }),
  }),
  updateUserPassword: builder.mutation({
    query: (body) => ({
      url: 'users/update-password',
      method: 'PUT',
      body,
    }),
  }),
  // TODO: Seems like an inconsistency since billing details is returned, not company
  editBillingDetails: builder.mutation<CompanyResponse, any>({
    query: (body) => ({
      url: '/users/self/billingDetails',
      method: 'PUT',
      body,
    }),
  }),
});
