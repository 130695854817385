import { combineReducers } from 'redux';

import apiSlice from './api';
import auth from './auth';
import billing from './billing';
import filters from './filters';
import notifications from './notifications';
import parkingAndReservations from './parkingAndReservations';
import portal from './portal';
import sites from './sites';
import transactions from './transactions';
import vehicles from './vehicles';

export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: auth,
  transactions: transactions,
  sites: sites,
  billing: billing,
  portal: portal,
  notifications: notifications,
  parkingAndReservations: parkingAndReservations,
  filters: filters,
  vehicles: vehicles,
});
