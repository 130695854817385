import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import { Global } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';

import { Header } from 'app/layouts/LoggedinBasicLayout/Header';
import { selectFiltersOpen } from 'services/filters/selectors';
import { updateBottomSheetHeight } from 'services/portal';
import { selectPublicSitesFiltered } from 'services/sites/selectors';

import { Filters } from './Filters';
import { SiteList } from './SiteList';
import { SiteListHeader } from './SiteListHeader';

export const PublicSiteList = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 568px)');
  const filtersOpen = useSelector(selectFiltersOpen);
  const sheetRef = useRef<BottomSheetRef>(null);
  const sites = useSelector(selectPublicSitesFiltered);

  return (
    <div className="relative bg-white flex flex-col w-0 sm:w-full sm:min-w-[400px] sm:max-w-[400px] h-full overflow-auto no-scrollbar">
      <Header className="sticky z-10 top-0 hidden sm:flex flex-col" />
      <div className="relative z-10 sm:hidden h-full">
        <Global
          styles={{
            '[data-rsbs-header]': { boxShadow: 'none' },
            '[data-rsbs-scroll]::-webkit-scrollbar ': { display: 'none' },
          }}
        />
        <BottomSheet
          open
          scrollLocking={false}
          className="sm:hidden"
          ref={sheetRef}
          blocking={false}
          defaultSnap={({ maxHeight }) => maxHeight / 2}
          snapPoints={({ maxHeight }) => [maxHeight * 0.35, maxHeight * 0.9]}
          onSpringStart={() => {
            requestAnimationFrame(() =>
              dispatch(updateBottomSheetHeight(sheetRef.current?.height)),
            );
          }}
          onSpringEnd={() => dispatch(updateBottomSheetHeight(sheetRef.current?.height))}
          header={
            filtersOpen ? true : <SiteListHeader isMobile={isMobile} numberOfSites={sites.length} />
          }
        >
          {filtersOpen ? <Filters /> : <SiteList sites={sites} />}
        </BottomSheet>
      </div>
      <div className="hidden sm:flex flex-col h-full">
        {filtersOpen ? (
          <Filters />
        ) : (
          <Box>
            <SiteListHeader isMobile={isMobile} numberOfSites={sites.length} />
            <SiteList sites={sites} />
          </Box>
        )}
      </div>
    </div>
  );
};
