import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Divider, Typography } from '@mui/material';

import staticMarker from 'app/images/icons/staticMarker.svg';
import { Modal } from 'components/modals/Modal';
import { googleApiKey } from 'config/consts';
import { format } from 'date-fns';
import { TransactionHandler } from 'handlers/transaction.handler';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';
import { Invoice } from 'services/billing';

type InvoiceItemProps = {
  label: string;
  value: string;
};

const InvoiceItem = ({ label, value }: InvoiceItemProps) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="p14" color="#999898">
      {label}
    </Typography>
    <Typography variant="p14">{value}</Typography>
  </Box>
);

type TransactionModalProps = {
  transaction: Transaction;
  invoice?: Invoice;
  site?: Site;
  onClose: () => void;
};

export const TransactionModal = ({
  transaction,
  invoice,
  site,
  onClose,
}: TransactionModalProps) => {
  const { t } = useTranslation();
  const handler = new TransactionHandler(transaction);

  const siteCoordinates = site?.addressJson
    ? `${site?.addressJson?.lat},${site?.addressJson?.lng}`
    : undefined;

  return (
    <Modal
      header={
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{transaction.siteName}</Typography>
          <Typography variant="p14" color="#999898">
            {format(new Date(transaction.startTime), 'dd MMM, HH:mm')}
          </Typography>
        </Box>
      }
      open={!!transaction}
      onClose={onClose}
    >
      {siteCoordinates && (
        <Box position="relative" display="flex" justifyContent="center" alignItems="center">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${siteCoordinates}&zoom=15&size=600x300&maptype=roadmap&map_id=cf767194ca2b13e0&key=${googleApiKey}`}
            alt="Map"
            style={{ borderRadius: '20px' }}
          />
          <img
            src={staticMarker}
            alt="marker"
            style={{
              position: 'absolute',
              top: 'calc(50% - 23px)',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={1}>
        <InvoiceItem
          label={t('vehicle', 'Vehicle')}
          value={
            transaction.carBrand && transaction.carModel
              ? `${transaction.carBrand} ${transaction.carModel}`
              : transaction.carName ?? t('unknownCar', 'Unknown car')
          }
        />
        <InvoiceItem label={t('duration', 'Duration')} value={handler.getDuration()} />
        <InvoiceItem label={t('consumption', 'Consumption')} value={handler.getAmount(2)} />
        <InvoiceItem
          label={t('charging', 'Charging')}
          value={handler.getMonetaryRevenueWithVAT(2)}
        />
        <InvoiceItem
          label={t('reservation', 'Reservation')}
          value={handler.getReservationCost(2)}
        />
        <InvoiceItem label={t('parking', 'Parking')} value={handler.getParkingCost(2)} />
      </Box>
      <Divider sx={{ height: '2px' }} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="p18">{t('total', 'Total')}</Typography>
        <Typography variant="p18">{handler.getTotalCost(2)}</Typography>
      </Box>
      {invoice?.hosted_invoice_url && (
        <Link to={invoice.hosted_invoice_url} target="_blank">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="48px"
            borderRadius="150px"
            sx={{ backgroundColor: '#D6D6D6', cursor: 'pointer' }}
          >
            <Typography variant="p16b">{t('downloadInvoice', 'Download invoice')}</Typography>
          </Box>
        </Link>
      )}
    </Modal>
  );
};
