import { useTranslation } from 'react-i18next';

import { availableLanguages, fallbackLanguage } from 'i18nextConf';

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const browserLang = i18n.language;
  const currentLang =
    availableLanguages.find((lng) => browserLang.includes(lng)) ?? fallbackLanguage;
  return (
    <div>
      <div className="flex z-20 items-center px-16 sm:pb-1 sm:px-12">
        {availableLanguages.map((lng) => (
          <button
            key={lng}
            type="button"
            className={`w-10 sm:m-3 m-4 h-10 rounded-full text-sm hover:bg-vool-gray-100 ${
              currentLang === lng
                ? 'sm:bg-white text-primary bg-gray-600 bg-opacity-25 font-semibold'
                : 'sm:text-black text-white'
            }`}
            onClick={() => {
              i18n.changeLanguage(lng);
            }}
          >
            {lng.toUpperCase()}
          </button>
        ))}
      </div>
    </div>
  );
};
