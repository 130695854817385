import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import exitIcon from 'app/images/icons/exit.svg';
import { Modal } from 'components/modals/Modal';
import { Car } from 'models/car';

import { VehicleItem } from './VehicleItem';

type VehicleModalProps = {
  isOpen: boolean;
  close: () => void;
  vehicles: Car[];
  onVehicleItemClick: (id?: string) => void;
};

export const VehicleModal = ({
  isOpen,
  close,
  vehicles,
  onVehicleItemClick,
}: VehicleModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      onClose={close}
      header={
        <Typography variant="h3"> {t('chooseYourVehicle', 'Choose your vehicle')}</Typography>
      }
    >
      <div className="flex flex-col space-y-3">
        <ul className="flex flex-col text-xl space-y-3">
          {vehicles.map((vehicle) => (
            <VehicleItem key={vehicle.id} vehicle={vehicle} onClick={onVehicleItemClick} />
          ))}
        </ul>
        <ul className="flex flex-col text-xl">
          <button
            className="flex space-x-4 items-center"
            onClick={() => onVehicleItemClick(undefined)}
          >
            <div className="flex border bg-primary rounded-2xl w-14 h-14">
              <img className="flex m-4" src={exitIcon} alt="exit" />
            </div>
            <div>
              <div className="flex text-base space-x-2">
                {t('unknownVehicle', 'Unknown vehicle')}
              </div>
            </div>
          </button>
        </ul>
      </div>
    </Modal>
  );
};
