import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Company } from 'models/company';
import { User } from 'models/user';
import api from 'services/api';

import { removeCredentials } from './actions';

export interface AuthState {
  user?: User;
  company?: Company;
}

const initialState: AuthState = {
  user: undefined,
  company: undefined,
};

const setUser = (
  state: AuthState,
  { payload }: PayloadAction<{ user: User; company?: Company }>,
) => {
  state.user = payload.user;
  state.company = payload.company;
};

const setCompany = (state: AuthState, { payload }: PayloadAction<{ company?: Company }>) => {
  state.company = payload.company;
};

const clearAuth = (state: AuthState) => {
  state.user = undefined;
  state.company = undefined;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeCredentials, clearAuth);

    builder.addMatcher(api.endpoints.login.matchFulfilled, setUser);
    builder.addMatcher(api.endpoints.self.matchFulfilled, setUser);
    builder.addMatcher(api.endpoints.updateSelf.matchFulfilled, setUser);
    builder.addMatcher(api.endpoints.editBillingDetails.matchFulfilled, setCompany);
  },
});

export { removeCredentials };

export default authSlice.reducer;
