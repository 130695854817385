import { OcppStatus } from 'handlers/device/ocpp-status.enum';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';

import { createEvses, getEvseStatus } from './device/device.handler';

export class SiteHandler {
  site: Site;

  constructor(site: Site) {
    this.site = site;
  }

  getData() {
    return this.site;
  }

  isMarketPrice() {
    const { site } = this;
    const revenuePricingModel = site.revenuePricingModel;
    return revenuePricingModel?.setting === 'market';
  }

  getPublicFixedPrice() {
    const { site } = this;
    return site.revenuePricingModel?.fixed ?? '-';
  }

  isSomeChargerCharging(activeTransaction?: Transaction) {
    const devices = this.getData().devices || [];
    const evses = devices.flatMap((device) => createEvses(device));
    return evses.some((evse) => getEvseStatus(evse, activeTransaction) === OcppStatus.CHARGING);
  }
}
