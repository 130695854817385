import React from 'react';
import { useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Dialog, useMediaQuery } from '@mui/material';

import exitIcon from 'app/images/icons/exitWithBackground.svg';
import { selectPortalLogo } from 'services/portal/selectors';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  header?: React.ReactNode;
  coverImage?: string;
};

export const Modal = ({ open, onClose, children, header, coverImage }: ModalProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { filename, url } = useSelector(selectPortalLogo);
  const logo = filename ? require(`app/images/${filename}`) : url;

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          borderRadius: isMobile ? '0px' : '20px',
          width: '100%',
          maxWidth: isMobile ? '100%' : '556px',
        },
      }}
    >
      <Box display="flex" flexDirection="column" height={1} gap={coverImage ? 5 : 1} pb={4}>
        {coverImage && (
          <Box display="flex" justifyContent="center" mt={-7.5} px={isMobile ? 2 : 4}>
            <img
              src={coverImage}
              alt="Modal cover image"
              className="xs:h-[496px] sm:h-[360px] w-full rounded-b-2xl object-cover"
            />
          </Box>
        )}
        <Box
          position={coverImage ? 'absolute' : 'relative'}
          display="flex"
          alignItems="center"
          width={1}
          p={isMobile ? '12px 16px 0 4px' : '40px 32px 0 32px'}
        >
          {isMobile && (
            <Box onClick={onClose} p={1.5} sx={{ cursor: 'pointer' }}>
              <ArrowBackIcon />
            </Box>
          )}
          <Box flexGrow={1}>{header}</Box>
          {coverImage && (
            <Box position="absolute" sx={{ left: '50%', transform: 'translateX(-50%)' }}>
              <img src={logo} alt="Company logo" className="w-16" />
            </Box>
          )}
          {!isMobile && (
            <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
              <img src={exitIcon} alt="exit" className="h-12 w-12" />
            </Box>
          )}
        </Box>
        {isMobile ? (
          <Box px={2} height={1}>
            {children}
          </Box>
        ) : (
          <Box px={`${coverImage ? 32 : 106}px`}>{children}</Box>
        )}
      </Box>
    </Dialog>
  );
};
