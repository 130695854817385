import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Card } from 'components/atoms/Card';
import { getEvseStatus, getEvseStatusImage } from 'handlers/device/device.handler';
import { OcppStatus } from 'handlers/device/ocpp-status.enum';
import { SiteHandler } from 'handlers/site.handler';
import { Evse } from 'models/device';
import { ConnectorType } from 'models/device.enums';
import { Site } from 'models/site';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForDevice } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { ConnectorIcon } from '../PublicSites/ConnectorIcon';

type SinglePublicChargerProps = {
  device: Evse;
  selectActiveDevice: (device: Evse) => void;
  active: boolean;
  disabled: boolean;
  site: Site;
  siteDeviceMaxPower: number;
  siteMaxPowerLoading: boolean;
};

export const SinglePublicCharger = ({
  device,
  selectActiveDevice,
  active,
  disabled,
  site,
  siteMaxPowerLoading,
}: SinglePublicChargerProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  // TODO: Same logic problems as elsewhere already
  const activeTransaction = useSelector(selectActiveTransaction);
  const activeReservation = useSelector(selectActiveReservation);

  const siteHandler = new SiteHandler(site);
  const fixedPublicPrice = siteHandler.getPublicFixedPrice();
  let status = getEvseStatus(device, activeTransaction, activeReservation);

  if (
    (status as OcppStatus) === OcppStatus.RESERVED &&
    activeReservation?.deviceUuid === device.uuid
  ) {
    status = OcppStatus.AVAILABLE;
  }
  const statusImage = getEvseStatusImage(device, activeTransaction, activeReservation);
  const deviceFeatures = [
    {
      title: t('pricing', 'Pricing'),
      text: siteHandler.isMarketPrice() ? t('market', 'Market') : t('fixed', 'Fixed'),
      disabled: !siteHandler.isMarketPrice(),
    },
    {
      title: siteHandler.isMarketPrice() ? t('upcharge', 'Upcharge') : t('price', 'Price'),
      text: `${fixedPublicPrice}€/kWh`,
      disabled: siteHandler.isMarketPrice(),
    },
    {
      title: t('power', 'Power'),
      text: `${device.maxPowerKw}kW`,
      loading: siteMaxPowerLoading,
    },
    {
      title: t('type', 'Type'),
      text: ConnectorType[device.connectorType as keyof typeof ConnectorType],
      icon: <ConnectorIcon type={device.connectorType} />,
    },
  ];

  const handleSelectActiveDevice = () => {
    selectActiveDevice(device);
    trackAmplitudeEvent(
      AMPLITUDE_EVENTS.SELECT_CHARGER,
      getAmplitudeEventPropertiesForDevice({ device, site, user }),
    );
  };

  return (
    <div
      role="button"
      className="w-full border-none"
      onKeyDown={() => {}}
      onClick={handleSelectActiveDevice}
      tabIndex={0}
    >
      <Card
        full
        className={`
          ${active ? 'shadow-none' : ''}
          ${disabled ? 'opacity-60' : ''}
          bg-vool-gray-100
          ${!siteHandler.isMarketPrice() ? 'xs:max-h-120px' : ''}
        `}
        radiusClass="rounded-2xl"
        borderless
      >
        <div className="flex items-center space-x-4 p-4 w-full">
          <img src={statusImage} className="w-14 h-14" alt="Online Available Chargers" />
          <div className="flex flex-col w-full min-w-0">
            <div className="flex justify-between items-start w-full">
              <div className="flex flex-col w-full">
                <span className="text-base font-semibold truncate w-full">
                  {device.name ?? device.serialNumber}
                </span>
                <span className="text-sm font-normal">{t(status, status)}</span>
              </div>
            </div>
            <div className="flex flex-wrap items-start justify-between w-full">
              {deviceFeatures.map(
                (feature) =>
                  !feature.disabled && (
                    <div
                      key={feature.title}
                      className={`flex flex-col items-start justify-center font-normal ${
                        siteHandler.isMarketPrice() ? 'w-1/2 xs:w-auto' : ''
                      }`}
                    >
                      <span className="text-xs text-vool-gray-400">{feature.title}</span>
                      <span className="flex text-sm font-normal">
                        {feature.loading ? '-' : feature.text}
                        {feature.icon}
                      </span>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
