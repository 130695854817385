import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import infoIcon from 'app/images/icons/info.svg';
import americanExpressLogo from 'app/images/icons/payments/americanExpress.svg';
import applePayLogo from 'app/images/icons/payments/applePay.svg';
import googlePayLogo from 'app/images/icons/payments/googlePay.svg';
import mastercardLogo from 'app/images/icons/payments/mastercard.svg';
import unionPayLogo from 'app/images/icons/payments/unionPay.svg';
import visaLogo from 'app/images/icons/payments/visa.svg';
import { ChargingAndReservationInfoModal } from 'app/views/Charger/ChargingAndReservationInfoModal';
import { Site } from 'models/site';
import { selectPortalConfig } from 'services/portal/selectors';

type ChargeIntroMessageProps = {
  className?: string;
  hasPaymentMethods: boolean;
  site: Site;
};

export const ChargeIntroMessage = ({
  className,
  hasPaymentMethods,
  site,
}: ChargeIntroMessageProps) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const {
    host: { paymentReceiver, bookedAmount },
  } = useSelector(selectPortalConfig) ?? { host: {} };
  const paymentsImages = [
    {
      src: applePayLogo,
      alt: 'Apple Pay',
    },
    {
      src: googlePayLogo,
      alt: 'Google Pay',
    },
    {
      src: visaLogo,
      alt: 'Visa',
    },
    {
      src: mastercardLogo,
      alt: 'Mastercard',
    },
    {
      src: americanExpressLogo,
      alt: 'American Express',
      fullSize: true,
    },
    {
      src: unionPayLogo,
      alt: 'Union Pay',
    },
  ];

  return (
    <div className={`flex items-start pt-3 justify-center xs:space-x-2 ${className}`}>
      <img src={infoIcon} alt="info stripe payment" className="h-5" />
      <div className="flex flex-col justify-center space-y-2">
        <div className="flex flex-col pb-2">
          <span className="text-xs font-normal">
            <Trans i18nKey="IHaveReadInformation">
              I Have read{' '}
              <button
                type="button"
                className="text-primary border-b border-primary"
                onClick={() => setInfoModalOpen(true)}
              >
                Charging and pricing information
              </button>
            </Trans>
          </span>
        </div>
        {!hasPaymentMethods && (
          <div className="flex items-center justify-between space-x-1">
            {paymentsImages.map((image) => (
              <div
                key={image.alt}
                className={`flex items-center justify-center bg-vool-gray-100 rounded w-9 h-6 ${
                  !image.fullSize ? 'px-1' : ''
                }`}
              >
                <img src={image.src} alt={image.alt} className="object-contain" />
              </div>
            ))}
          </div>
        )}
      </div>
      <ChargingAndReservationInfoModal
        isOpen={infoModalOpen}
        closeModal={() => setInfoModalOpen(false)}
        site={site}
        paymentReceiver={paymentReceiver}
        bookedAmount={bookedAmount}
      />
    </div>
  );
};
