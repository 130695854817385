import { Button } from './Button';

type GreenButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

export const GreenButton = ({ children, onClick }: GreenButtonProps) => (
  <Button
    onClick={onClick}
    radiusClass="rounded-full"
    className="w-full py-4 px-8 bg-vool-green text-white"
    fontSize="text-md"
  >
    {children}
  </Button>
);
